import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import { sendVerificationCode, validateVerificationCode } from "../../util/httpsfunctions";
import PropTypes from 'prop-types';
import { Input as BaseInput } from '@mui/base/Input';
import { Box, styled } from '@mui/system';
import Alert from "@mui/joy/Alert";
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/joy/LinearProgress';
import { CheckCircleOutline } from "@mui/icons-material";
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';

function OTP({ separator, length, value, onChange, pending }) {
  const inputRefs = useRef(new Array(length).fill(null));

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const selectInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event, currentIndex) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case 'Delete':
        event.preventDefault();
        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });

        break;
      case 'Backspace':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event, currentIndex) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange((prev) => {
      const otpArray = prev.split('');
      const lastValue = currentValue[currentValue.length - 1];
      otpArray[indexToEnter] = lastValue;
      return otpArray.join('');
    });
    if (currentValue !== '') {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (event, currentIndex) => {
    selectInput(currentIndex);
  };

  const handlePaste = (event, currentIndex) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain');
      pastedText = pastedText.substring(0, length).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = value.split('');

      for (let i = indexToEnter; i < length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? ' ';
        otpArray[i] = lastValue;
      }

      onChange(otpArray.join(''));
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      {new Array(length).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <BaseInput
            type="tel"
            disabled={pending}
            slots={{
              input: InputElement,
            }}
            aria-label={`Digit ${index + 1} of OTP`}
            slotProps={{
              input: {
                ref: (ele) => {
                  inputRefs.current[index] = ele;
                },
                onKeyDown: (event) => handleKeyDown(event, index),
                onChange: (event) => handleChange(event, index),
                onClick: (event) => handleClick(event, index),
                onPaste: (event) => handlePaste(event, index),
                value: value[index] ?? '',
              },
            }}
          />
          {index === length - 1 ? null : separator}
        </React.Fragment>
      ))}
    </Box>
  );
}

OTP.propTypes = {
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  separator: PropTypes.node,
  value: PropTypes.string.isRequired,
};

export default function OTPInput({ formik, submissionAlert, setSubmissionAlert }) {
  const [otp, setOtp] = useState('');
  const [alert, setAlert] = useState({
    type: "",
    message: "",
  });
  const [pending, setPending] = useState(false);

  async function handleResend(email) {
    try {
      await sendVerificationCode(formik.values.WORK_EMAIL);
      setAlert({ type: "primary", message: "sent a new verification code to " + email });
      setOtp('');
    } catch (error) {
      console.error(error);
      setAlert({ type: "danger", message: "Error sending verification code: " + error });
    }
  }

  useEffect(() => {
    async function validate() {
      if (otp.length === 6) {
        setPending(true);
        try {
          const res = await validateVerificationCode({
            email: formik.values.WORK_EMAIL,
            code: otp
          })
          if (res.data.code === 200) {
            setPending(false);
            setAlert({
              type: "success",
              message: "Email verified"
            });
            formik.setFieldValue("WORK_EMAIL_VERIFIED", true);
          }
        } catch (error) {
          setPending(false);
          setAlert({
            type: "danger",
            message: error.message
          });
        }
      }
    }
    validate();
  }, [otp]); //eslint-disable-line

  if (formik.values.WORK_EMAIL_VERIFIED) {
    return (
      <Card variant="outlined" sx={{ maxWidth: 650, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid  xs={12}>
            <Typography level="h4" endDecorator={<CheckCircleOutline color="success" />}>Email Verified</Typography>
          </Grid>
          <Grid  xs={12}>
            <Typography level="body-lg">Your email is verified. You are ready to submit!</Typography>
          </Grid>
          {submissionAlert && <Grid  xs={12}><Alert color="danger" size="sm" variant="outlined"  endDecorator={
            <React.Fragment>
              <IconButton color="neutral" size="sm" onClick={() => {
                setSubmissionAlert("");
              }}>
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }>{submissionAlert}</Alert></Grid>}
          <Grid  xs={12}>
            <FormControl size="sm">
              <Checkbox
                onChange={formik.handleChange}
                name="POLICY_AGREEMENT"
                value={formik.values.POLICY_AGREEMENT}
                label={
                  <React.Fragment>
                    I have read and agree to the{' '}
                    <Typography fontWeight="md">terms and conditions and privacy policy</Typography>.
                  </React.Fragment>
                }
              />
              <FormHelperText>
                <Typography level="body-sm">
                  Read our <Link href="./legal" underline="always">terms and conditions and privacy policy</Link>.
                </Typography>
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    )
  } else {
    return (
      <Card variant="outlined" sx={{ maxWidth: 650, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid  xs={12}>
            <Typography level="h4">Enter Your Verification Code</Typography>
          </Grid>
          <Grid  xs={12}>
            <Typography level="body-md">We sent a verification code to {formik.values.WORK_EMAIL}</Typography>
          </Grid>
          {alert.message !== "" && alert.type !== "" && <Grid  xs={12}><Alert color={alert.type} variant="outlined" size="sm"
            endDecorator={
              <React.Fragment>
                <IconButton size="sm" onClick={() => {
                  setAlert({ type: "", message: "" });
                  setOtp("");
                }}>
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          >{alert.message}</Alert ></Grid>}
          <Grid  xs={12}>
            <OTP separator={<span>-</span>} value={otp} onChange={setOtp} length={6} pending={pending} />
            {pending && <LinearProgress sx={{ mt: 2 }} />}
          </Grid>
          <Grid >
            <Typography level="body-sm">Did not get a code? <Link underline="always" level="body-sm" onClick={() => handleResend(formik.values.WORK_EMAIL)}>Resend Code</Link></Typography>
          </Grid>
        </Grid>
      </Card>
    )
  }
}

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const InputElement = styled('input')(
  ({ theme }) => `
  width: 40px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0px;
  border-radius: 8px;
  text-align: center;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
