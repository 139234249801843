import React from "react";
import Meta from "../components/general/Meta";
import HospitalSection from "../components/hospital/HospitalSection";
import Navbar from "../components/general/Navbar";
import Footer from "../components/general/Footer";
import logo from "../images/logo.png";

function HospitalPage(props) {
  return (
    <>
      <Meta title="Overview" />
      <Navbar
        color="default"
        logo={logo}
        showSearch={true}
      />
      <HospitalSection
        bgColor="white"
        size="normal"
        routePathname={props.routePathname}
      />
      <Footer
        bgColor="default"
        bgImage=""
        bgImageOpacity={1}
        description="Creating pay and work condition transparency in nursing"
        copyright={`© ${new Date().getFullYear()} RealNurse Inc.`}
        logo={logo}
        sticky={true}
      />
    </>
  );

}

export default HospitalPage;
