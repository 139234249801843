import * as React from 'react';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import { Check } from '@mui/icons-material';
import { Circle } from '@mui/icons-material';

export default function ContributeSectionStepper({ page }) {
  return (
    <Stepper sx={{ width: '100%', my: 4 }} size="sm">
      <Step
        orientation="vertical"
        indicator={page == 0 ? 
          <StepIndicator variant="outlined" color="success">
            <Circle />
          </StepIndicator> :
          <StepIndicator variant="solid" color={page >= 0 ? "success" : "neutral"}>
            {page > 0 && <Check />}
          </StepIndicator>
        }
      >
        Workplace
      </Step>
      <Step orientation="vertical" indicator={page == 1 ? 
          <StepIndicator variant="outlined" color="success">
            <Circle />
          </StepIndicator> :
          <StepIndicator variant="solid" color={page >= 1 ? "success" : "neutral"}>
            {page > 1 && <Check />}
          </StepIndicator>
        }
      >
        Career
      </Step>
      <Step orientation="vertical" indicator={page == 2 ? 
          <StepIndicator variant="outlined" color="success">
            <Circle />
          </StepIndicator> :
          <StepIndicator variant="solid" color={page >= 2 ? "success" : "neutral"}>
            {page > 2 && <Check />}
          </StepIndicator>
        }>
        Salary
      </Step>
      <Step orientation="vertical" indicator={page == 3 ? 
          <StepIndicator variant="outlined" color="success">
            <Circle />
          </StepIndicator> :
          <StepIndicator variant="solid" color={page >= 3 ? "success" : "neutral"}>
            {page > 3 && <Check />}
          </StepIndicator>
        }>
        Ratings</Step>
        <Step orientation="vertical" indicator={page == 4 ? 
          <StepIndicator variant="outlined" color="success">
            <Circle />
          </StepIndicator> :
          <StepIndicator variant="solid" color={page >= 4 ? "success" : "neutral"}>
            {page > 4 && <Check />}
          </StepIndicator>
        }>
        Verify</Step>
    </Stepper>
  );
}