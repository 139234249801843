import Container from "@mui/material/Container"
import React from "react";
import Section from "../general/Section";
import AboutHospitalSection from "./AboutHospitalSection";
import { useHospital } from "../../util/db";
import { useParams } from "react-router";
import HospitalHeader from "./HospitalHeader";
import Grid from "@mui/joy/Grid";
import LatestGeneralSubmissionsTable from "./LatestGeneralSubmissionsTable";
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import DisplayRatings from "../general/DisplayRatings";
import { ENTITIES } from "../../constants";

export default function HospitalSection(props) {
    let { hospitalId } = useParams();
    const { data, status } = useHospital(hospitalId);
    if (status === "success") {
        return (
            <Section bgColor={props.bgColor}
                size={props.size}>
                <Container>

                    <Grid container spacing={1}>
                        <Grid xs={12}>
                            <HospitalHeader routePathname={props.routePathname} data={data} status={status} shareUrl={`https://realnurse.info/hospitals/${hospitalId}/about`} />
                        </Grid>
                        <Grid  xs={12} mt={2}>
                            <Typography level="h4">Hospital Overview</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <AboutHospitalSection data={data} status={status} />
                        </Grid>
                        <Grid  xs={12} mt={2}>
                            <Typography level="h4">Average Ratings</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <DisplayRatings data={data} entity={ENTITIES.hospital}/>
                        </Grid>
                        <Grid  xs={12} mt={2}>
                            <Typography level="h4">Latest Submissions</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <LatestGeneralSubmissionsTable hospitalId={hospitalId} />
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    } else {
        return (
            <Section bgColor={props.bgColor}
                size={props.size}>
                <Container>
                            <LinearProgress >Loading...</LinearProgress>
                </Container>
            </Section>
        )
    }
}