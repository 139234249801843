import React from "react";
import Section from "../general/Section";
import Container from "@mui/material/Container";
import Grid from "@mui/joy/Grid";
import SubmissionCard from "./SubmissionCard";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CallToAddSubmission from "../general/CallToAddSubmission";
import { useHospital, useSpecialties } from "../../util/db";
import { useParams } from "react-router";
import { specialties } from "../../constants";
import Link from "@mui/joy/Link";
import HospitalHeader from "../hospital/HospitalHeader";
import { toLowercaseName } from "../../util/util";
import LinearProgress from "@mui/joy/LinearProgress";

export default function AllSubmissionsSection(props) {
    let { hospitalId } = useParams();
    const { data: hospitalData, status: hospitalStatus } = useHospital(hospitalId);
    const { data: specialtiesData, status: specialtiesStatus } = useSpecialties(hospitalId);
    const shareUrl = `https://realnurse.info/hospitals/${hospitalId}/about`;

    if (hospitalStatus === "success" && specialtiesStatus === "success") {
        const formattedName = toLowercaseName(hospitalData.NAME)
        return (
            <Section bgColor={props.bgColor}
                size={props.size}>
                <Container>
                    <HospitalHeader routePathname={props.routePathname} data={hospitalData} status={hospitalStatus} shareUrl={shareUrl} />
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Typography level="h4" mt={2}>{`Submissions by Sepcialty at ${formattedName}`}</Typography>
                        </Grid>
                        {specialtiesData.length === 0 &&
                            <Grid xs={12}>
                                <Card variant="outlined">
                                    <CallToAddSubmission />
                                </Card>
                            </Grid>
                        }
                        {specialtiesData.map((specialty) => {
                            const specialtyObj = specialties.find(t => t.value === specialty.id);
                            return (
                                <Grid key={specialty.id}>
                                    <Link href={`/hospitals/${hospitalId}/specialties/${specialty.id}`} underline="none">
                                        <SubmissionCard numberOfSubmissions={specialty.NUMBER_OF_SUBMISSIONS} specialty={specialtyObj.label} />
                                    </Link>

                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Section>
        )
    } else {
        return (
            <Section bgColor={props.bgColor}
            size={props.size}>
            <Container>
                <LinearProgress>Loading Submissions...</LinearProgress>
            </Container>
        </Section>
        )
    }
}