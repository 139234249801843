import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import SearchIcon from "@mui/icons-material/Search";
import AutocompleteListBox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import { useRouter } from "../../util/router";

export default function LandingSearch({setOpen}) {
  const apiId = process.env.REACT_APP_ALGOLIA_API_ID;
  const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
  const searchClient = algoliasearch(apiId,apiKey);
  // (1) Create a React state.
  const [autocompleteState, setAutocompleteState] = useState({});
  const router = useRouter();
  useEffect(() => {
    return () => {
      setAutocompleteState({});
    }
  }, []);

  const onselect = (val) => {
    setOpen(false);
    router.push('/hospitals/' + val.item.objectID + '/about')
  }

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            {
              sourceId: "hptls",
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "hospitals",
                      query,
                      params: {
                        hitsPerPage: 5,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                });
              }, onSelect: onselect
            },
          ];
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return (
    <div {...autocomplete.getRootProps({})}>
      <form
      >
        <FormControl id="input-with-icon-textfield">
          <Input
            size="lg"
            sx={{ width:"100%"}}
            {...autocomplete.getInputProps({})}
            autoFocus={true}
            variant="outlined"
            placeholder="Search for any U.S. hospital..."
            startDecorator={<SearchIcon />}
          />
        </FormControl>

        <Box>
          {autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;
                return (
                  <AutocompleteListBox
                  key={"listBox-" + index}
                  {...autocomplete.getPanelProps({})}
                  sx={{ zIndex: 1000, width: "100%", boxShadow: "none", border: 0, background: 'none' }}>
                  {items.map((item) => {
                    return (
                      <AutocompleteOption
                        variant="soft"
                        sx={{mb: 1, borderRadius: 5}}
                        key={"listItem-" + item.objectID}
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                      >
                        <div key={"listItem-" + item.objectID + "-div"}>
                          <Typography fontSize={14} fontWeight={700} key={"listItem" + item.objectID + "-name"}>
                            {item.NAME}
                          </Typography>
                          <Typography fontSize={12} fontWeight={500} key={"listItem" + item.objectID + "-state-city"}>
                            {`${item.CITY}, ${item.STATE}`}
                          </Typography>
                        </div>
                      </AutocompleteOption>
                    )
                  }
                  )}
                </AutocompleteListBox>
                );
            })}
        </Box>
      </form>
    </div>
  );
}
