import React from "react";
import Meta from "../components/general/Meta";
import AllSubmissionsSection from "../components/allsubmissions/AllSubmissionsSection";
import Navbar from "../components/general/Navbar";
import Footer from "../components/general/Footer";
import logo from "../images/logo.png";

function AllSubmissionsPage(props) {
    return (
      <>
        <Meta title="Ratings and Salaries" />
        <Navbar
                color="default"
                logo={logo}
                showSearch={true}
            />
        <AllSubmissionsSection
          bgColor="white"
          size="normal"
          routePathname={props.routePathname}
        />
        <Footer
                bgColor="default"
                bgImage=""
                bgImageOpacity={1}
                description="Creating pay and work condition transparency in nursing"
                copyright={`© ${new Date().getFullYear()} RealNurse Inc.`}
                logo={logo}
                sticky={true}
            />
      </>
    );
  }


export default AllSubmissionsPage;
