import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/joy/Grid";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import LinkMui from "@mui/joy/Link";
import { Link } from "../../util/router";
import Divider from "@mui/joy/Divider";

function Footer(props) {
  return (
    <Box mb={2}>
      <Divider sx={{ mb: 2 }} />
      <Container>
        <Grid container={true} justifyContent="center" alignItems="center" spacing={2}>
        <Grid >
            <Typography level="body-md">{props.copyright}</Typography>
          </Grid>
          <Grid >
            {props.description && (
                <Typography component="p" level="body-md" fontStyle="italic">{props.description}</Typography>
            )}
          </Grid>
          <Grid >
            <LinkMui underline="always" component={Link} to="/legal">
              Terms of Service and Privacy Policy
            </LinkMui>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
