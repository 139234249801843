import React from "react";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import LegalTerms from "./LegalTerms";
import LegalPrivacy from "./LegalPrivacy";

function LegalSection(props) {
  return (
    <Tabs aria-label="Basic tabs" defaultValue={0}>
      <TabList>
        <Tab>Terms Of Service</Tab>
        <Tab>Privacy Policy</Tab>
      </TabList>
      <TabPanel value={0}>
        <LegalTerms />
      </TabPanel>
      <TabPanel value={1}>
        <LegalPrivacy />
      </TabPanel>
    </Tabs>
  );
}

export default LegalSection;
