import React from "react";
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Chip from '@mui/joy/Chip';
import { stress_options } from "../../constants";

function getChipColor(value) {
    if (value === undefined) {
        return "neutral";
    }
    if (value <= 2) {
        return "success";
    } else if (value <= 3.5) {
        return "warning";
    } else {
        return "danger";
    }
}

function getRatingText(value) {
    if (value <= 2) {
        return stress_options[1].label;
    } else if (value <= 3) {
        return stress_options[2].label;
    } else if (value <= 4) {
        return stress_options[3].label;
    } else {
        return stress_options[4].label;
    }
}

export default function StressLevelRating({ value, title }) {
    return (
        <Box minWidth={200}>
            <Typography mb={1} level="body-md">{title}</Typography>
            <Chip variant="soft" sx={{height: 30}} size="md" color={getChipColor(value)}>{value !== undefined ? getRatingText(Math.round(value)) : "No data yet"}</Chip>
        </Box>
    )
}