import React from "react";
import Meta from "../components/general/Meta";
import SpecialtySection from "../components/specialty/SpecialtySection";
import Navbar from "../components/general/Navbar";
import Footer from "../components/general/Footer";
import logo from "../images/logo.png";

function SpecialtyPage(props) {
    return (
        <>
            <Meta title="Specialty" />
            <Navbar
                color="default"
                logo={logo}
                showSearch={true}
            />
            <SpecialtySection routePathname={props.routePathname} bgColor="white"
        size="normal"/>
            <Footer
                bgColor="default"
                bgImage=""
                bgImageOpacity={1}
                description="Creating pay and work condition transparency in nursing"
                copyright={`© ${new Date().getFullYear()} RealNurse Inc.`}
                logo={logo}
                sticky={true}
            />
        </>
    );
}

export default SpecialtyPage;