import React, { useState } from "react";
import Typography from '@mui/joy/Typography';
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import Button from '@mui/joy/Button';
import IosShareIcon from '@mui/icons-material/IosShare';
import ShareUrlModal from "../general/ShareUrlModal";
import SpecialtyTabBar from "./SpecialtyTabBar";

export default function SpecialtyHeader({ data, specialtyId, shareUrl, routePathname }) {

    const [open, setOpen] = useState(false);

        return (
            <Box>
                <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareUrl} />
                <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid  xs={12}>
                        <Typography level="h2" fontWeight={700}>
                            {data.NAME}
                        </Typography>
                    </Grid>
                    <Grid  md='auto'>
                        <Typography level="h4" textColor="neutral.400">
                            {`${data.CITY}, ${data.STATE}`}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button variant="outlined" color="neutral" size="sm" startDecorator={<IosShareIcon />} onClick={() => setOpen(true)}>
                            Share This Hospital
                        </Button>
                    </Grid>
                    <Grid  xs={12}>
                        <SpecialtyTabBar routePathname={routePathname} specialtyId={specialtyId} hospitalId={data.id} />
                    </Grid>
                </Grid>
            </Box>
        );
}