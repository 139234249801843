import React from "react";
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import { specialties, shift_options, level_of_education_options } from "../../constants";

export default function CareerSection({ formik }) {
    const handleSelectSpecialtyChange = (event, newValue) => {
        formik.setFieldValue("SPECIALTY", newValue);
    };

    const handleSelectShiftChange = (event, newValue) => {
        formik.setFieldValue("SHIFT", newValue);
    };

    const handleSelectLevelOfEducationChange = (event, newValue) => {
        formik.setFieldValue("LEVEL_OF_EDUCATION", newValue);
    };

    return (
        <Card variant="outlined" sx={{maxWidth: 650}}>
            <Grid container spacing={2}>
                <Grid  xs={12}>
                    <Typography level="h4">Career Info</Typography>
                </Grid>
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.SPECIALTY) && formik.touched.SPECIALTY} required>
                        <FormLabel>Select Specialty</FormLabel>
                        <Select onChange={handleSelectSpecialtyChange} id="select-specialty" name="SPECIALTY" value={formik.values.SPECIALTY} placeholder="Please select one">
                            {specialties.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.SPECIALTY && formik.touched.SPECIALTY) && <FormHelperText>{formik.errors.SPECIALTY}</FormHelperText>}
                    </FormControl>
                </Grid>
                {formik.values.SPECIALTY === "other" &&
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.OTHER_SPECIALTY) && formik.touched.OTHER_SPECIALTY} required>
                            <FormLabel>Enter Other Specialty</FormLabel>
                            <Input
                                placeholder="Enter Other Specialty"
                                id="enter-other-specialty"
                                name="OTHER_SPECIALTY"
                                label="Other Specialty"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {(Boolean(formik.errors.OTHER_SPECIALTY) && formik.touched.OTHER_SPECIALTY) &&
                                <FormHelperText>{formik.errors.OTHER_SPECIALTY}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }
                 <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.LEVEL_OF_EDUCATION) && formik.touched.LEVEL_OF_EDUCATION} required>
                        <FormLabel>Select level of Education</FormLabel>
                        <Select onChange={handleSelectLevelOfEducationChange} id="select-level-of-education" name="LEVEL_OF_EDUCATION" value={formik.values.LEVEL_OF_EDUCATION} placeholder="Please select one">
                            {level_of_education_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.SHIFT && formik.touched.SHIFT) && <FormHelperText>{formik.errors.SHIFT}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.SHIFT) && formik.touched.SHIFT} required>
                        <FormLabel>Select Primary Shift</FormLabel>
                        <Select onChange={handleSelectShiftChange} id="select-shift" name="SHIFT" value={formik.values.SHIFT} placeholder="Please select one">
                            {shift_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.SHIFT && formik.touched.SHIFT) && <FormHelperText>{formik.errors.SHIFT}</FormHelperText>}
                    </FormControl>
                </Grid>
                {formik.values.SHIFT === "other" &&
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.OTHER_SHIFT) && formik.touched.OTHER_SHIFT} required>
                            <FormLabel>Enter Other Shift</FormLabel>
                            <Input
                                placeholder="Enter Other Shift"
                                id="enter-other-shift"
                                name="OTHER_SHIFT"
                                label="Other Shift"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {(Boolean(formik.errors.OTHER_SHIFT) && formik.touched.OTHER_SHIFT) &&
                                <FormHelperText>{formik.errors.OTHER_SHIFT}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }
                <Grid  xs={12} sm={6}>
                    <FormControl error={Boolean(formik.errors.YEARS_AT_HOSPITAL && formik.touched.YEARS_AT_HOSPITAL && formik.touched.YEARS_OF_EXPERIENCE)} required>
                        <FormLabel>Years at Current Hospital</FormLabel>
                        <Input
                            placeholder="Enter years at hospital"
                            id="years-at-hospital"
                            name="YEARS_AT_HOSPITAL"
                            label="Years at Hospital"
                            type="number"
                            value={formik.values.YEARS_AT_HOSPITAL}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                        />
                        {(Boolean(formik.errors.YEARS_AT_HOSPITAL) && formik.touched.YEARS_AT_HOSPITAL && formik.touched.YEARS_OF_EXPERIENCE) &&
                            <FormHelperText>{formik.errors.YEARS_AT_HOSPITAL}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12} sm={6}>
                    <FormControl error={Boolean(formik.errors.YEARS_OF_EXPERIENCE && formik.touched.YEARS_OF_EXPERIENCE && formik.touched.YEARS_AT_HOSPITAL)} required>
                        <FormLabel>Years of Experience</FormLabel>
                        <Input
                            placeholder="Enter years of experience"
                            id="years-of-experience"
                            name="YEARS_OF_EXPERIENCE"
                            label="Years of Experience"
                            type="number"
                            value={formik.values.YEARS_OF_EXPERIENCE}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                        />
                        {(Boolean(formik.errors.YEARS_OF_EXPERIENCE) && formik.touched.YEARS_OF_EXPERIENCE && formik.touched.YEARS_AT_HOSPITAL) && 
                            <FormHelperText>{formik.errors.YEARS_OF_EXPERIENCE}</FormHelperText>}
                    </FormControl>
                </Grid>
               
            </Grid>
        </Card>
    )
}