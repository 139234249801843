import React from "react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Grid from "@mui/joy/Grid";


export default function SubmissionCard({specialty, numberOfSubmissions}) {
    return (
            <Card sx={{ p: 2}} variant="outlined">
                <Typography level="h5" sx={{ fontWeight: 700, mr: 1 }} color="primary">
                    {specialty}
                </Typography>
                <Grid container spacing={2} sx={{ py: 1 }}>
                    <Grid>
                        <Chip size="lg" variant="soft" color="neutral">{`${numberOfSubmissions} submissions`}</Chip>
                    </Grid>
                </Grid>
            </Card>
    )
}