import React from "react";
import Grid from "@mui/joy/Grid";
import Button from "@mui/joy/Button";
import Modal from '@mui/joy/Modal';
import FormControl from '@mui/joy/FormControl';
import forms from "../../util/forms";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/joy/Typography';
import Card from "@mui/joy/Card";
import CardOverflow from '@mui/joy/CardOverflow';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import Link from '@mui/joy/Link';
import Launch from '@mui/icons-material/Launch';
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from '@mui/joy/ModalClose';
import Textarea from "@mui/joy/Textarea";
import { InfoOutlined } from "@mui/icons-material";
import { EHR, PARKING } from "../../constants";

export default function AboutHospitalSection(props) {
    const data = props.data;
    const status = props.status;
    const [incorrectModalOpen, setIncorrectModalOpen] = React.useState(false);
    const handleIncorrectModalOpen = () => setIncorrectModalOpen(true);
    const handleIncorrectModalClose = () => setIncorrectModalOpen(false);
    const [missingModalOpen, setMissingModalOpen] = React.useState(false);
    const handleMissingModalOpen = () => setMissingModalOpen(true);
    const handleMissingModalClose = () => setMissingModalOpen(false);

    const [pending, setPending] = React.useState(false);

    const onMissingInfoSubmit = (message) => {
        // Show pending indicator
        if (message.length > 0) {
        setPending(true);
        forms
            .submitMissingInfo({
                "hospitalName": data.NAME,
                "hospitalId": data.id,
                "message": message,
            })
            .then(() => {
                // Clear form
                // Show success alert message

            })
            .catch((error) => {
                // Show error alert message

            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
                setMissingModalOpen(false);
            });
        }
    };

    const onIncorrectInfoSubmit = (message) => {
        if (message.length > 0) {
        // Show pending indicator
        setPending(true);

        forms
            .submitIncorrectInfo({
                "hospitalName": data.NAME,
                "hospitalId": data.id,
                "message": message,
            })
            .then(() => {
                // Clear form

            })
            .catch((error) => {
                // Show error alert message

            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
                setIncorrectModalOpen(false);
            });
        }
    };

    function getEhrSystem(ehr) { 
        if (ehr === undefined) {
            return "UNKNOWN";
        } else if (ehr === EHR.EPIC.value) {
            return "Epic";
        } else if (ehr === EHR.CERNER.value) { 
            return "Cerner";
        } else if (ehr === EHR.OTHER.value) {
            return "Not Epic or Cerner";
        } else {
            return "UNKNOWN";
        }
    }

    function getParking(parking) {
        if (parking === undefined) {
            return "UNKNOWN";
        } else if (parking === PARKING.ON_SITE.value) {
            return "On-site";
        } else if (parking === PARKING.OFF_SITE.value) {
            return "Off-site (transport bus required)";
        } else if (parking === PARKING.OTHER.value) {
            return "Multiple Parking Options";
        } else {
            return "UNKNOWN";
        }
    }

    if (status === "success") {
        return (
            <Card variant="outlined">
                <Modal
                    open={incorrectModalOpen}
                    onClose={handleIncorrectModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalDialog>
                        <ModalClose variant="outlined" />
                        <form onSubmit={onIncorrectInfoSubmit}>
                            <Typography id="modal-modal-title" level="title-lg">
                                Found An Error?
                            </Typography>
                            <Typography id="modal-modal-description" my={2} level="body-lg">
                                Please tell us which info is incorrect so we can correct it as soon as possible.
                            </Typography>
                            <FormControl id="outlined-incorrect-textarea">
                                <Textarea
                                    minRows={2}
                                    name="incorrect info"
                                    placeholder="Tell Us What We Can Fix :)"
                                    variant="outlined"
                                />

                            </FormControl>
                            <Button sx={{ mt: 2 }} type="submit">{!pending && <span>Submit</span>}
                                {pending && <CircularProgress size={28} />}</Button>
                        </form>
                    </ModalDialog>

                </Modal>
                <Modal
                    open={missingModalOpen}
                    onClose={handleMissingModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalDialog>
                        <ModalClose variant="outlined" />
                        <form onSubmit={onMissingInfoSubmit}>
                            <Typography id="modal-modal-title" level="title-lg">
                                Got Info?
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ my: 2 }} level="body-lg">
                                Know the correct information for an "UNKNOWN" field? Help others out and contribute the missing info.
                            </Typography>
                            <FormControl id="outlined-info-textarea">
                                <Textarea
                                    minRows={2}
                                    name="missing info"
                                    placeholder="Tell Us What You Know :)"
                                    variant="outlined"
                                />
                            </FormControl>
                            <Button sx={{ mt: 2 }} type="submit">{!pending && <span>Submit</span>}
                                {pending && <CircularProgress size={28} />}</Button>
                        </form>
                    </ModalDialog>
                </Modal>
                <CardOverflow
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'flex-start',
                        px: 'var(--Card-padding)',
                        pb: 0
                    }}
                >
                        <Dropdown>
                            <MenuButton startDecorator={<InfoOutlined/>} variant="outlined" size="sm">
                                Missing or Incorrect Info?
                            </MenuButton>
                            <Menu>
                                <MenuItem onClick={handleIncorrectModalOpen}>Report Incorrect Info</MenuItem>
                                <MenuItem onClick={handleMissingModalOpen}>Report Missing Info</MenuItem>
                            </Menu>
                        </Dropdown>
                </CardOverflow>
                <Grid container spacing={2} justifyContent="flex-start" mb={2}>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Beds Count</Typography>
                        <Typography level="body-md">{data.BEDS === "NOT AVAILABLE" ? "UNKNOWN" : data.BEDS}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Hospital Type</Typography>
                        <Typography level="body-md">{data.TYPE === "NOT AVAILABLE" ? "UNKNOWN" : data.TYPE}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Teaching Hospital</Typography>
                        <Typography level="body-md">{data.TEACHING ? "YES" : "UNKNOWN"}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Paid Preceptorship</Typography>
                        <Typography level="body-md">{data.HAS_PAID_PRECEPTORSHIP ? "YES" : "UNKNOWN"}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Owner</Typography>
                        <Typography level="body-md">{data.OWNER === "NOT AVAILABLE" ? "UNKNOWN" : data.OWNER}</Typography>
                    </Grid>
                    <Grid xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Trauma Center</Typography>
                        <Typography level="body-md">{data.TRAUMA === "NOT AVAILABLE" ? "UNKNOWN" : data.TRAUMA}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Helipad</Typography>
                        {data.HELIPAD === "NOT AVAILABLE" ? <Typography level="body-md">UNKNOWN</Typography> : data.HELIPAD === "Y" ? <Typography level="body-md">YES</Typography> : <Typography level="h6">NO</Typography>}
                    </Grid>
                    <Grid xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">EHR System</Typography>
                        <Typography level="body-md">{getEhrSystem(data.EHR)}</Typography>
                    </Grid>
                    <Grid xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Parking</Typography>
                        <Typography level="body-md">{getParking(data.PARKING)}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Phone</Typography>
                        <Typography level="body-md">{data.TELEPHONE === "NOT AVAILABLE" ? "UNKNOWN" : data.TELEPHONE}</Typography>
                    </Grid>
                    <Grid  xs={6} sm={3} md={3} lg={2}>
                        <Typography level="title-md">Website</Typography>
                        {data.WEBSITE === "NOT AVAILABLE" ? <Typography level="body-md">UNKNOWN</Typography> : <Link target="_blank" href={data.WEBSITE} endDecorator={<Launch />}>
                            <Typography level="body-md" color="primary">Visit</Typography>
                        </Link>}
                    </Grid>
                </Grid>
            </Card>
        );
    }
}