import React, { useEffect, useState } from "react";
import Typography from "@mui/joy/Typography";
import { useNewSubmission } from "../../util/db";
import { verifySubmission } from "../../util/httpsfunctions";
import CircularProgress from "@mui/joy/CircularProgress";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";

export default function ExamineSection({ submissionId, setSubmissionId }) {
    const { data: submissionData, status: submissionStatus, error: submissionError, } = useNewSubmission(submissionId);

    async function onSubmit() {
        try {
            await verifySubmission(submissionId);
            setSubmissionId("");
        } catch (error) {
            console.log(error)
        }
    }

    const [dataArr, setDataArr] = useState([]);

    useEffect(() => {
        if (submissionStatus === "success") {
            let tempArr = [];
            for (const [key, value] of Object.entries(submissionData)) {
                tempArr.push({ key, value });
            }
            tempArr.sort((a, b) => a.key.localeCompare(b.key))
            setDataArr(tempArr);
        }
    }, [submissionData, submissionStatus]);

    if (submissionStatus === "success") {
        return (

            <List sx={{
                overflow: 'scroll',
                mx: 'calc(-1 * var(--ModalDialog-padding))',
                px: 'var(--ModalDialog-padding)',
            }}>
                {
                    dataArr.map(keyValPair => {
                        return (
                            <ListItem key={keyValPair.key + "-text"}>{`${keyValPair.key}: ${keyValPair.value}`}</ListItem>
                        );
                    })
                }
                <ListItemButton onClick={onSubmit} variant="solid" color="success">Verify Submission</ListItemButton>
            </List>

        )
    } else if (submissionStatus === "loading" || submissionStatus === "idle") {
        return (
            <CircularProgress />
        )
    } else {
        return <Typography>{submissionError}</Typography>
    }
}