import React, { useState } from "react";
import Box from "@mui/joy/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/joy/Grid";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from "@mui/joy/Button";
import RatingsSection from "./RatingsSection";
import HospitalInfoSection from "./HospitalInfoSection";
import CompensationSection from "./CompensationSection";
import CareerSection from "./CareerSection";
import CircularProgress from "@mui/joy/CircularProgress";
import SubmitSectionStepper from "./ContributeSectionStepper";
import { Link, Typography, useTheme } from "@mui/joy";
import { Favorite, KeyboardArrowLeft, KeyboardArrowRight, Lock, Send, Timer } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import logo from "../../images/logo.png";
import VerificationSection from "./VerificationSection";
import { checkIfEmailAlreadyExistsInSubmission, sendVerificationCode } from "../../util/httpsfunctions";
import OTPInput from "./OtpInput";
import { createNewHospitalSubmission, createNewSubmission } from "../../util/db";
import SuccessSection from "./SuccessSection";

const useStyles = makeStyles((theme) => ({
    logo: {
        height: 60,                                                                                                  
    },
}));

export default function ContributeSection(props) {
    const [page, setPage] = useState(0);
    const [checked, setChecked] = useState(false);
    const [submissionStarted, setSubmissionStarted] = useState(false);
    const theme = useTheme();
    const [alert, setAlert] = useState("");
    const [pending, setPending] = useState(false);

    const classes = useStyles();

    const validationSchema = yup.object({
        WORKPLACE_NAME: yup.string().required("Name of workplace is required"),
        WORKPLACE_CITY: yup.string().required("City of workplace is required"),
        WORKPLACE_STATE: yup.string().required("State of workplace is required"),
        WORKPLACE_ID: yup.string(),
        WORKPLACE_IS_NEW_ENTRY: yup.boolean().required(),
        EHR: yup
            .string()
            .required("Please select an EHR"),
        OTHER_EHR: yup.string().when('EHR', {
            is: (EHR) => EHR === "other",
            then: schema => schema.required("Required when EHR/EMR is set to other"),
            otherwise: schema => schema.notRequired()
        }),
        PARKING: yup.string().required("Parking is required"),
        OTHER_PARKING: yup.string().when('PARKING', {
            is: (PARKING) => PARKING === "other",
            then: schema => schema.required("Required when Parking is set to other"),
            otherwise: schema => schema.notRequired()
        }),
        BASE_RATE: yup
            .number()
            .required("Please provide your base rate"),
        WEEKEND_DIFFERENTIAL_RATE: yup
            .number()
            .required("Please provide the weekend differential rate"),
        NIGHT_DIFFERENTIAL_RATE: yup
            .number()
            .required("Please provide the night differential rate"),
        CHARGE_NURSE_DIFFERENTIAL_RATE: yup
            .number()
            .required("Please provide the charge nurse rate"),
        HAS_PAID_PRECEPTORSHIP: yup
            .boolean()
            .required("Please select yes or no"),
        SPECIALTY: yup
            .string()
            .required("Please select a specialty"),
        OTHER_SPECIALTY: yup.string().when('SPECIALTY', {
            is: (SPECIALTY) => SPECIALTY === "other",
            then: schema => schema.required("Required when Specialty is set to other"),
            otherwise: schema => schema.notRequired()
        }),
        LEVEL_OF_EDUCATION: yup.string().required("Please select your level of education"),
        SHIFT: yup
            .string()
            .required("Please select your primary shift"),
        OTHER_SHIFT: yup.string().when('SHIFT', {
            is: (SHIFT) => SHIFT === "other",
            then: schema => schema.required("Required when shift is set to other"),
            otherwise: schema => schema.notRequired()
        }),
        YEARS_AT_HOSPITAL: yup
            .number().max(yup.ref('YEARS_OF_EXPERIENCE'), "Years at Hospital should be less than or equal to Years of Experience"),
        YEARS_OF_EXPERIENCE: yup
            .number().required("Years of Experience is required"),
        RN_TO_PATIENT_RATIO_RATING: yup
            .number()
            .required("Please provide a rating"),
        NURSING_SUPPORT_RATING: yup
            .number()
            .required("Please provide a rating"),
        HOSPITAL_MANAGEMENT_RATING: yup
            .number()
            .required("Please provide a rating"),
        ONBOARDING_RATING: yup
            .number()
            .required("Please provide a rating"),
        STRESS_LEVEL: yup
            .number()
            .required("Please provide a stress level"),
        WORK_EMAIL: yup
            .string()
            .email("Email address is not valid")
            .required("Please provide your work email. This is neccessary to verify you work at your hospital."),
    });


    async function handleSubmit(values) {
        setPending(true);
        try {
            if (values.WORKPLACE_IS_NEW_ENTRY) {
                await createNewHospitalSubmission(values.WORKPLACE);
            }
            await createNewSubmission(values);
            setPending(false);
            setPage(page + 1);
        } catch (error) {
            console.error(error);
            setAlert(error.message);
            setPending(false);
        }
    }

    async function handleClick(email) {
        if (formik.values.WORK_EMAIL_VERIFIED) {
            setPage(page + 1);
        } else {
            setPending(true);
            try {
                await sendVerificationCode(email);
                setPending(false);
                setPage(page + 1);
            } catch (error) {
                console.error(error);
                setPending(false);
                setAlert(error.message);
            }
        }
    }    

    const formik = useFormik({
        initialValues: {
            WORKPLACE_NAME: "",
            WORKPLACE_CITY: "",
            WORKPLACE_STATE: "",
            WORKPLACE_ID: "",
            WORKPLACE_IS_NEW_ENTRY: false,
            PARKING: "",
            OTHER_PARKING: "",
            SPECIALTY: "",
            OTHER_SPECIALTY: "",
            LEVEL_OF_EDUCATION: "",
            EHR: "",
            OTHER_EHR: "",
            BASE_RATE: "",
            WEEKEND_DIFFERENTIAL_RATE: "",
            WEEKEND_DIFFERENTIAL_METRIC: "DOLLAR",
            NIGHT_DIFFERENTIAL_RATE: "",
            NIGHT_DIFFERENTIAL_METRIC: "DOLLAR",
            CHARGE_NURSE_DIFFERENTIAL_RATE: "",
            CHARGE_NURSE_DIFFERENTIAL_METRIC: "DOLLAR",
            HAS_PAID_PRECEPTORSHIP: null,
            SHIFT: "",
            OTHER_SHIFT: "",
            YEARS_AT_HOSPITAL: "",
            YEARS_OF_EXPERIENCE: "",
            RN_TO_PATIENT_RATIO_RATING: null,
            NURSING_SUPPORT_RATING: null,
            HOSPITAL_MANAGEMENT_RATING: null,
            ONBOARDING_RATING: null,
            STRESS_LEVEL: null,
            WORK_EMAIL: "",
            WORK_EMAIL_VERIFIED: false,
            POLICY_AGREEMENT: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.RN_TO_PATIENT_RATIO_RATING = Number(values.RN_TO_PATIENT_RATIO_RATING);
            values.NURSING_SUPPORT_RATING = Number(values.NURSING_SUPPORT_RATING);
            values.HOSPITAL_MANAGEMENT_RATING = Number(values.HOSPITAL_MANAGEMENT_RATING);
            values.ONBOARDING_RATING = Number(values.ONBOARDING_RATING);
            values.STRESS_LEVEL = Number(values.STRESS_LEVEL);
            handleSubmit(values);
        },
    })

    return (
        <Container>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                {!submissionStarted &&
                    <Grid container mt={4} spacing={4}>
                        <Grid>
                            <Box display="inline-flex" alignItems="center">
                                <img src={logo} alt="Logo" className={classes.logo} />
                                <Typography ml={2} fontWeight={700} fontSize={28} color="primary">
                                    Real Nurse
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="h2">Contribute Your Info to Realnurse</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="title-md" startDecorator={<Timer />}>Only Takes 60 seconds</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="title-md" startDecorator={<Lock sx={{ color: theme.colorSchemes.light.palette.primary[400] }} />}>Your contribution is anonymous and your identity will be protected</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="title-md" startDecorator={<Favorite sx={{ color: theme.colorSchemes.light.palette.danger[500] }} />}>
                                Your data empowers other nurses to argue for better pay, benefits, and work conditions</Typography>
                        </Grid>
                        <Grid >
                            <Button size="lg" onClick={() => setSubmissionStarted(true)} endDecorator={<KeyboardArrowRight />}>Begin</Button>
                        </Grid>
                    </Grid>}
                {submissionStarted &&
                    <form onSubmit={formik.handleSubmit}>
                        <SubmitSectionStepper page={page} />
                        {page === 0 &&
                            <>
                                <HospitalInfoSection formik={formik} checked={checked} setChecked={setChecked} />
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Button endDecorator={<KeyboardArrowRight />} onClick={() => setPage(page + 1)} disabled={
                                            Boolean(formik.errors.WORKPLACE_NAME) ||
                                            Boolean(formik.errors.WORKPLACE_STATE) ||
                                            Boolean(formik.errors.WORKPLACE_CITY) ||
                                            Boolean(formik.errors.EHR) ||
                                            Boolean(formik.errors.PARKING) ||
                                            Boolean(formik.errors.OTHER_EHR) ||
                                            Boolean(formik.errors.OTHER_PARKING)
                                        } size="lg">Next</Button>
                                    </Box>
                            </>
                        }
                        {page === 1 &&
                            <>
                                <CareerSection formik={formik} />
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Button startDecorator={<KeyboardArrowLeft />} sx={{ mr: 1 }} onClick={() => setPage(page - 1)} size="lg" >Previous</Button>
                                        <Button endDecorator={<KeyboardArrowRight />} onClick={() => setPage(page + 1)} disabled={
                                            Boolean(formik.errors.SPECIALTY) ||
                                            Boolean(formik.errors.YEARS_AT_HOSPITAL) ||
                                            Boolean(formik.errors.YEARS_OF_EXPERIENCE)
                                        } size="lg">Next</Button>
                                    </Box>
                            </>
                        }
                        {page === 2 && <>
                            <CompensationSection formik={formik} />
                                <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                    <Button startDecorator={<KeyboardArrowLeft />} sx={{ mr: 1 }} onClick={() => setPage(page - 1)} size="lg">Previous</Button>
                                    <Button endDecorator={<KeyboardArrowRight />} onClick={() => setPage(page + 1)} disabled={
                                        Boolean(formik.errors.BASE_RATE) ||
                                        Boolean(formik.errors.WEEKEND_DIFFERENTIAL_RATE) ||
                                        Boolean(formik.errors.NIGHT_DIFFERENTIAL_RATE) ||
                                        Boolean(formik.errors.CHARGE_NURSE_DIFFERENTIAL_RATE) ||
                                        Boolean(formik.errors.HAS_PAID_PRECEPTORSHIP)
                                    } size="lg">Next</Button>
                                </Box>
                        </>
                        }

                        {page === 3 &&
                            <>
                                <RatingsSection formik={formik} />
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Button startDecorator={<KeyboardArrowLeft />} sx={{ mr: 1 }} onClick={() => setPage(page - 1)} size="lg">Previous</Button>
                                        <Button endDecorator={<KeyboardArrowRight />} onClick={() => setPage(page + 1)} disabled={
                                            Boolean(formik.errors.RN_TO_PATIENT_RATIO_RATING) ||
                                            Boolean(formik.errors.NURSING_SUPPORT_RATING) ||
                                            Boolean(formik.errors.HOSPITAL_MANAGEMENT_RATING) ||
                                            Boolean(formik.errors.ONBOARDING_RATING) ||
                                            Boolean(formik.errors.STRESS_LEVEL)
                                        } size="lg">Next</Button>
                                    </Box>
                            </>
                        }
                        {page === 4 &&
                            <>
                                <VerificationSection formik={formik} alert={alert} setAlert={setAlert} />
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Button startDecorator={<KeyboardArrowLeft />} sx={{ mr: 1 }} onClick={() => setPage(page - 1)} size="lg">Previous</Button>
                                        <Button endDecorator={pending ? <CircularProgress /> : <KeyboardArrowRight />} onClick={() => handleClick(formik.values.WORK_EMAIL)} disabled={
                                            Boolean(formik.errors.WORK_EMAIL) || pending
                                        } size="lg">Next</Button>
                                    </Box>
                            </>
                        }
                        {page === 5 &&
                            <>
                                <OTPInput formik={formik} submissionAlert={alert} setSubmissionAlert={setAlert}/>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Button startDecorator={<KeyboardArrowLeft />} sx={{ mr: 1 }} onClick={() => { setPage(page - 1) }} size="lg">Previous</Button>
                                        <Button size="lg" endDecorator={pending ? <CircularProgress/> : <Send />} color="success" 
                                        type="submit"
                                        disabled={
                                            pending ||
                                            !formik.values.WORK_EMAIL_VERIFIED || 
                                            !formik.values.POLICY_AGREEMENT}>
                                                Submit
                                                </Button>
                                    </Box>
                            </>
                        }
                        {page === 6 &&
                            <>
                                <SuccessSection />
                                <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                                        <Link href="/" underline="none">
                                        <Button size="lg" endDecorator={<KeyboardArrowRight />}>Go To Home Page</Button>
                                        </Link>
                                        </Box>
                            </>
                        }

                    </form>
                }
            </Box>
        </Container>
    )
}