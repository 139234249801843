import React from "react";
import Meta from "../components/general/Meta";
import Auth from "../components/auth/Auth";

function SignInPage(props) {
  return (
    <>
      <Meta title="Sign In" />
      <Auth/>
    </>
  );
}

export default SignInPage;
