import React from "react";
import Meta from "../components/general/Meta";
import Navbar from "../components/general/Navbar";
import Footer from "../components/general/Footer";
import logo from "../images/logo.png";
import LandingSection from "../components/landing/LandingSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo={logo}
        showSearch={true}
      />
      <LandingSection/>
      <Footer
        bgColor="default"
        bgImage=""
        bgImageOpacity={1}
        description="Creating pay and work condition transparency in nursing"
        copyright={`© ${new Date().getFullYear()} RealNurse Inc.`}
        logo={logo}
        sticky={true}
      />
    </>
  );
}

export default IndexPage;