import React from "react";
import Meta from "../components/general/Meta";
import SubmissionSection from "../components/submission/SubmissionSection";
import Navbar from "../components/general/Navbar";
import Footer from "../components/general/Footer";
import logo from "../images/logo.png";

function SubmissionPage(props) {
    return (
        <>
            <Meta title="Submission" />
            <Navbar
                color="default"
                logo={logo}
                showSearch={true}
            />
            <SubmissionSection bgColor="white"
                size="normal" />
            <Footer
                bgColor="default"
                bgImage=""
                bgImageOpacity={1}
                description="Creating pay and work condition transparency in nursing"
                copyright={`© ${new Date().getFullYear()} RealNurse Inc.`}
                logo={logo}
                sticky={true}
            />
        </>
    );
}

export default SubmissionPage;