import * as React from 'react';
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import Typography from "@mui/joy/Typography";

export default function SpecialtyScatterPlot({ dataset }) {
    const max = Math.max(...dataset.map(o => o.y)) + 5;
    const min = Math.min(...dataset.map(o => o.y)) - 5;

    return (
        <>
            <Typography level="title-lg" mb={2}>Compensation Data Points</Typography>
                <ScatterChart
                    grid={{ vertical: true, horizontal: true }}
                    width={375}
                    height={400}
                    series={[{
                        data: dataset,
                        valueFormatter: ({ x, y }) => `${x} years of experience, $${y}/hr`,
                    }]}
                    xAxis={[{ min: 0, valueFormatter: (v) => `${v} years`, label: "Years of Experience" }]}
                    yAxis={[{ min: min, max: max, valueFormatter: (v) => `$${v}/hr`}]}

                />
        </>
    );
}
