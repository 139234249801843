import React from "react";
import IndexPage from "./index";
import HospitalPage from "./hospitals";
import AllSubmissionsPage from "./allsubmissions";
import SubmissionPage from "./submission";
import SubmitSubmissionPage from "./contribute";
import LegalPage from "./legal";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import Chat from "../components/general/Chat";
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import SpecialtyPage from "./specialty";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Chat />
          <Router>
              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/legal" component={LegalPage} />
                
                <Route exact path="/auth" component={AuthPage} />

                <Route exact path="/hospitals/:hospitalId/about" render={(history) => (
                  <HospitalPage routePathname={history.location.pathname} />
                )} />

                <Route exact path="/hospitals/:hospitalId/specialties" render={(history) => (
                  <AllSubmissionsPage routePathname={history.location.pathname} />
                )} />

                <Route exact path="/hospitals/:hospitalId/specialties/:specialtyId" render={(history) => (
                  <SpecialtyPage routePathname={history.location.pathname} />
                )} />

                <Route exact path="/submission/:submissionId" component={SubmissionPage} />

                <Route exact path="/contribute" component={SubmitSubmissionPage} />

                <Route exact path="/dashboard" component={DashboardPage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />
                <Route component={NotFoundPage} />
              </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
