import React from "react";
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Alert from "@mui/joy/Alert";
import IconButton from "@mui/joy/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export default function VerificationSection({ formik, alert, setAlert }) {
    
    return (
        <Card variant="outlined" sx={{ maxWidth: 650, mt: 2 }}>
            <Grid container spacing={2}>
                <Grid  xs={12}>
                    <Typography level="h4">Verification</Typography>
                </Grid>
                <Grid  xs={12}>
                    <Typography level="body-sm">We will send a code to your work email to verify that you are an employee.</Typography>
                </Grid>
                {alert && <Grid  xs={12}><Alert color="danger" size="sm" variant="outlined"  endDecorator={
            <React.Fragment>
              <IconButton color="neutral" size="sm" onClick={() => {
                setAlert("");
              }}>
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }>{alert}</Alert></Grid>}
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.WORK_EMAIL) && formik.touched.WORK_EMAIL} required disabled={formik.values.WORK_EMAIL_VERIFIED}>
                        <FormLabel>Work Email</FormLabel>
                        <Input
                            placeholder="Enter work email"
                            id="work-email"
                            name="WORK_EMAIL"
                            label="Work Email"
                            type="email"
                            value={formik.values.WORK_EMAIL}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {(Boolean(formik.errors.WORK_EMAIL) && formik.touched.WORK_EMAIL) &&
                            <FormHelperText>{formik.errors.WORK_EMAIL}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}