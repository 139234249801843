import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ExamineSection from './ExamineSection';
import { ModalDialog } from '@mui/joy';

export default function ExamineModal({submissionId, setSubmissionId}) {
  return (
    <React.Fragment>
      <Modal
        open={submissionId !== ""}
        onClose={() => setSubmissionId("")}
      >
        <ModalDialog layout="center">
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <ExamineSection submissionId={submissionId} setSubmissionId={setSubmissionId} />
          </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}