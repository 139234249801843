import React, { useState, useEffect } from "react";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import { useAuth } from "../../util/auth";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Alert from "@mui/joy/Alert";
import IconButton from '@mui/joy/IconButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LinkMui from "@mui/joy/Link";
import CloseIcon from '@mui/icons-material/Close';


export default function ForgotPassword({ setPage }) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [alert, setAlert] = useState({
        color: "",
        message: ""
    });

    function sendResetEmail(email) {
        auth.sendPasswordResetEmail(email).then(() => {
            setPending(false);
            setAlert({
                color: "success",
                message: "Password reset. Check your email for further instructions."
            })
        }).catch((error) => {
            setPending(false);
            setAlert({
                color: "danger",
                message: error.message
            })
        });
    }

    const validationSchema = yup.object({
        EMAIL: yup.string().email("Not a valid email").required("Email is required"),
    })

    const formik = useFormik({
        initialValues: {
            EMAIL: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setPending(true);
            sendResetEmail(values.EMAIL);
        }
    })

    useEffect(() => {
        console.log(formik.errors);
    }, [formik.errors])

    return (
        <Grid container={true} spacing={2} justifyContent="center" alignItems="center">
            <Grid  xs={12} textAlign="center">
                <Typography level="h2">Reset Password</Typography>
            </Grid>
            {alert.message.length > 0 &&
                <Grid  xs={12}>
                    <Alert
                        variant="outlined"
                        color={alert.color}
                        endDecorator={<IconButton onClick={() => setAlert({
                            color: "",
                            message: ""
                        })}><CloseIcon /></IconButton>}>
                        {alert.message}
                    </Alert>
                </Grid>}
            <Grid  xs={12}>
                <FormControl error={Boolean(formik.errors.EMAIL)}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        size="lg"
                        variant="outlined"
                        type="email"
                        label="Email"
                        name="EMAIL"
                        value={formik.values.EMAIL}
                        onChange={formik.handleChange}
                        placeholder="user@example.com"
                        fullWidth={true}
                    />
                    {Boolean(formik.errors.EMAIL) && <FormHelperText>{formik.errors.EMAIL}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid  xs={12}>
                <Button
                    onClick={formik.submitForm}
                    color="success"
                    size="lg"
                    type="submit"
                    disabled={
                        pending ||
                        Boolean(formik.errors.EMAIL)
                    }
                    fullWidth={true}
                    startDecorator={pending && <CircularProgress />}
                >
                    {!pending && <span>Reset Password</span>}
                </Button>
            </Grid>
            <Grid  mt={2}>
                <Typography>Remember your password after all?{' '}
                    <LinkMui onClick={() => setPage("signin")} underline="always">Sign In</LinkMui>
                </Typography>
            </Grid>
        </Grid>
    );
}