import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Typography from "@mui/joy/Typography";

const valueFormatter = (value) => `$${value}/hr`;

export default function SpecialtyBarChart({ dataset }) {
  return (
    <>
      <Typography level="title-lg" mb={2}>Compensation By Years of Experience</Typography>
        <BarChart
          slotProps={{
            legend: {
              position: {
                vertical: 'top',
                horizontal: 'middle',
              },
              itemMarkWidth: 20,
              itemMarkHeight: 3,
              markGap: 5,
              itemGap: 10,
            }
          }}
          borderRadius={2}
          dataset={dataset}
          margin={{ top: 100 }}
          xAxis={[{ scaleType: 'band', dataKey: 'YEARS_OF_EXPERIENCE',  valueFormatter: (v) => `${v} years`, label: "Years of Experience" }]}
          yAxis={[{ valueFormatter: (v) => `$${v}/hr`}]}
          series={[
            { dataKey: 'AVERAGE_BASE_RATE', label: 'Base Rate', valueFormatter },
            { dataKey: 'AVERAGE_NIGHT_DIFFERENTIAL', label: 'Night Differential.', valueFormatter },
            { dataKey: 'AVERAGE_WEEKEND_DIFFERENTIAL', label: 'Weekend Differential.', valueFormatter },
            { dataKey: 'AVERAGE_CHARGE_NURSE_DIFFERENTIAL', label: 'Charge Nurse Differential.', valueFormatter },
          ]}
          width={375}
          height={400}
        />
    </>
  );
}