
function extractAndNormalizeCompensation(doc) {

    let baseRate = Number(doc.BASE_RATE.toFixed(0)); 
    let weekendDif;
    let nightDif;
    let chargeDif;

    if (doc.WEEKEND_DIFFERENTIAL_METRIC === "PERCENTAGE") {
        weekendDif = doc.BASE_RATE * doc.WEEKEND_DIFFERENTIAL_RATE / 100;
    } else {
        weekendDif = doc.WEEKEND_DIFFERENTIAL_RATE;
    }

    if (doc.NIGHT_DIFFERENTIAL_METRIC === "PERCENTAGE") {
        nightDif = doc.BASE_RATE * doc.NIGHT_DIFFERENTIAL_RATE / 100;
    } else {
        nightDif = doc.NIGHT_DIFFERENTIAL_RATE;
    }

    if (doc.CHARGE_NURSE_DIFFERENTIAL_METRIC === "PERCENTAGE") {
        chargeDif = doc.BASE_RATE * doc.CHARGE_NURSE_DIFFERENTIAL_RATE / 100;
    } else {
        chargeDif = doc.CHARGE_NURSE_DIFFERENTIAL_RATE;
    }

    return {
        BASE_RATE: baseRate,
        WEEKEND_DIFFERENTIAL_RATE: weekendDif,
        NIGHT_DIFFERENTIAL_RATE: nightDif,
        CHARGE_NURSE_DIFFERENTIAL_RATE: chargeDif,
        YEARS_OF_EXPERIENCE: doc.YEARS_OF_EXPERIENCE,
    };
}

function extractToScatterFormat(doc) {
    return {
        x: doc.YEARS_OF_EXPERIENCE,
        y: doc.BASE_RATE,
        id: doc.id
    };
}

const calculateAverage = (array) => {
    if (array.length > 0) {
        const sum = array.reduce((a, b) => a + b);
        return sum / array.length;
    } else {
        return null;
    }
};

export function calculateAverageCompensationDataByYoe(submissions, minYear, maxYear) {
    var chartableDataArr = [];
    for (minYear; minYear < maxYear; minYear++) {
        var yearArr = [];

        for (let i = 0; i < submissions.length; i++) {
            if (submissions[i].YEARS_OF_EXPERIENCE === minYear) {
                yearArr.push(submissions[i]);
            }
        }

        if (yearArr.length === 0) {
            chartableDataArr.push({
                YEARS_OF_EXPERIENCE: minYear,
                AVERAGE_BASE_RATE: null,
                AVERAGE_NIGHT_DIFFERENTIAL: null,
                AVERAGE_WEEKEND_DIFFERENTIAL: null,
                AVERAGE_CHARGE_NURSE_DIFFERENTIAL: null,
            });
        } else {
            let baseRates = [];
            let nightDifferentials = [];
            let weekendDifferentials = [];
            let chargeNurseDifferentials = [];

            for (let x = 0; x < yearArr.length; x++) {
                const extractedData = extractAndNormalizeCompensation(yearArr[x]);
                baseRates.push(extractedData.BASE_RATE);
                nightDifferentials.push(extractedData.NIGHT_DIFFERENTIAL_RATE);
                weekendDifferentials.push(extractedData.WEEKEND_DIFFERENTIAL_RATE);
                chargeNurseDifferentials.push(extractedData.CHARGE_NURSE_DIFFERENTIAL_RATE);
            }

            let averageBaseRate = calculateAverage(baseRates);
            let averageNightDifferential = calculateAverage(nightDifferentials);
            let averageWeekendDifferential = calculateAverage(weekendDifferentials);
            let averageChargeNurseDifferential = calculateAverage(chargeNurseDifferentials);


            chartableDataArr.push({
                YEARS_OF_EXPERIENCE: minYear,
                AVERAGE_BASE_RATE: Number(averageBaseRate.toFixed(2)),
                AVERAGE_NIGHT_DIFFERENTIAL: Number(averageNightDifferential.toFixed(2)),
                AVERAGE_WEEKEND_DIFFERENTIAL: Number(averageWeekendDifferential.toFixed(2)),
                AVERAGE_CHARGE_NURSE_DIFFERENTIAL: Number(averageChargeNurseDifferential.toFixed(2)),
            });
        }
    }
    return chartableDataArr;
}

export function calculateAverageRatings(submissions) {
    const numberOfTitleSubmissions = submissions.length;
    if (numberOfTitleSubmissions === 0) {
        return {
            AVERAGE_RN_TO_PATIENT_RATIO_RATING: undefined,
            AVERAGE_NURSING_SUPPORT_RATING: undefined,
            AVERAGE_HOSPITAL_MANAGEMENT_RATING: undefined,
            AVERAGE_ONBOARDING_RATING: undefined,
            AVERAGE_STRESS_LEVEL: undefined
        }
    }
    // ratings
    let rnToPatientRatioRatingsSum = 0;
    let nursingSupportRatingsSum = 0;
    let hospitalManagementRatingsSum = 0;
    let onboardingRatingsSum = 0;
    let stressLevelRatingsSum = 0;

    submissions.forEach((docData) => {
        // ratings
        rnToPatientRatioRatingsSum += docData.RN_TO_PATIENT_RATIO_RATING;
        nursingSupportRatingsSum += docData.NURSING_SUPPORT_RATING;
        hospitalManagementRatingsSum += docData.HOSPITAL_MANAGEMENT_RATING;
        onboardingRatingsSum += docData.ONBOARDING_RATING;
        stressLevelRatingsSum += docData.STRESS_LEVEL;
    });

    const averageRnToPatientRatioRating = rnToPatientRatioRatingsSum / numberOfTitleSubmissions;
    const averageNursingSupportRating = nursingSupportRatingsSum / numberOfTitleSubmissions;
    const averageHospitalManagementRating = hospitalManagementRatingsSum / numberOfTitleSubmissions;
    const averageOnboardingRating = onboardingRatingsSum / numberOfTitleSubmissions;
    const averageStressLevelRating = stressLevelRatingsSum / numberOfTitleSubmissions;

    return {
        AVERAGE_RN_TO_PATIENT_RATIO_RATING: Number(averageRnToPatientRatioRating.toFixed(2)),
        AVERAGE_NURSING_SUPPORT_RATING: Number(averageNursingSupportRating.toFixed(2)),
        AVERAGE_HOSPITAL_MANAGEMENT_RATING: Number(averageHospitalManagementRating.toFixed(2)),
        AVERAGE_ONBOARDING_RATING: Number(averageOnboardingRating.toFixed(2)),
        AVERAGE_STRESS_LEVEL_RATING: Number(averageStressLevelRating.toFixed(2)),
    };
}

export function createScatterPlotData(submissions) {
    let chartableDataArr = [];
    submissions.forEach((submission) => chartableDataArr.push(extractToScatterFormat(submission)));
    return chartableDataArr;
}

export function calculateAverages(submissions) {
    const numberOfTitleSubmissions = submissions.length;
    if (numberOfTitleSubmissions < 2) {
        return {
            AVERAGE_BASE_RATE: undefined,
            AVERAGE_WEEKEND_DIFFERENTIAL_RATE: undefined,
            AVERAGE_NIGHT_DIFFERENTIAL_RATE: undefined,
            AVERAGE_CHARGE_NURSE_DIFFERENTIAL_RATE: undefined,
        }
    }
    
    let baseRateSum = 0;
    let weekendDifferentialSum = 0;
    let nightDifferentialSum = 0;
    let chargeNurseDifferentialSum = 0;

    submissions.forEach((docData) => {
        let normalizedData = extractAndNormalizeCompensation(docData);
        baseRateSum += normalizedData.BASE_RATE;
        weekendDifferentialSum += normalizedData.WEEKEND_DIFFERENTIAL_RATE;
        nightDifferentialSum += normalizedData.NIGHT_DIFFERENTIAL_RATE;
        chargeNurseDifferentialSum += normalizedData.CHARGE_NURSE_DIFFERENTIAL_RATE;
    });

    const averageBaseRate = baseRateSum/numberOfTitleSubmissions;
    const averageWeekendDifferentialRate = weekendDifferentialSum/numberOfTitleSubmissions;
    const averageNightDifferentialRate = nightDifferentialSum/numberOfTitleSubmissions;
    const averageChargeDifferentialRate = chargeNurseDifferentialSum/numberOfTitleSubmissions;

    const res = {
        AVERAGE_BASE_RATE: Number(averageBaseRate.toFixed(2)),
        AVERAGE_WEEKEND_DIFFERENTIAL_RATE: Number(averageWeekendDifferentialRate.toFixed(2)),
        AVERAGE_NIGHT_DIFFERENTIAL_RATE: Number(averageNightDifferentialRate.toFixed(2)),
        AVERAGE_CHARGE_NURSE_DIFFERENTIAL_RATE: Number(averageChargeDifferentialRate.toFixed(2)),
    };

    return res;
}