import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Button from "@mui/joy/Button";
import "./LandingSection.css";
import Section from "../general/Section";
import { Add, IosShare } from "@mui/icons-material";
import Link from "@mui/joy/Link";
import { useViewport } from "../../util/util";
import ShareUrlModal from "../general/ShareUrlModal";
import RecentSubmissionSection from "./RecentSubmissionSection";
import { Divider } from "@mui/joy";

export default function HeroText() {
    const { width } = useViewport();
    const breakpoint = 820;
    const [open, setOpen] = useState(false);
    const shareUrl = "https://realnurse.info/contribute";

    return (
        <Section
            size="normal"
        >
            <Container>
                <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareUrl}/>
                <Grid container spacing={4} justifyContent="center" alignItems="center" textAlign="center">
                    <Grid xs={12}>
                        <RecentSubmissionSection />
                    </Grid>
                    <Grid xs={12}>
                        <Typography level="body-md">
                        We are a community of nurses who share salary and work condition information to help each other make informed decisions. We believe that by sharing our information, we can help each other negotiate better pay and work conditions. Please contribute your information to help us build a more transparent and equitable nursing community.
                        </Typography>
                    </Grid>
                    <Grid  xs={12} md="auto">
                        <Link href="/contribute" underline="none" width="100%">
                            <Button size="lg" fullWidth={width > breakpoint ? false : true} color="primary" endDecorator={<Add />}>Contribute Your Info</Button>
                        </Link>
                    </Grid>
                    <Grid  xs={12} md="auto">
                        <Button size="lg" color="primary" variant="soft" fullWidth endDecorator={<IosShare />} onClick={() => setOpen(true)}>
                            Invite a Nurse
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={{mt: 4}}/>
        </Section>
    )
}