import React, {useState} from "react";
import Box from "@mui/joy/Box";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

export default function AuthSection() {
    const [page, setPage] = useState("signin");
    return (
        <Box my={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={2}>
            <Box sx={{ maxWidth: 400 }}>
                {page === "signin" && <SignIn setPage={setPage}/>}
                {page === "signup" && <SignUp setPage={setPage} />}
                {page === "forgotpassword" && <ForgotPassword setPage={setPage} />}
            </Box>
        </Box>
    )
}