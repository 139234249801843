import React from "react";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Box from "@mui/joy/Box";
import { toLowercaseName } from "../../util/util";
import { specialties } from "../../constants";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card"

export default function RecentSubmissionCard(props) {
    const submission = props.submission;
    const dateObj = new Date();
    const currentDate = dateObj.getTime() / 1000;
    const submissionDate = submission.createdAt.seconds;
    var seconds = currentDate - submissionDate;
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    const workplaceName = toLowercaseName(submission.WORKPLACE_NAME);
    const city = toLowercaseName(submission.WORKPLACE_CITY);
    const specialtyObj = specialties.find(t => t.value === submission.SPECIALTY);
    return (
        <Box sx={{ textAlign: "left" }}>
            <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center" mb={0.5}>
                <AccessTimeIcon fontSize="10" sx={{ mr: 0.5 }} />
                <Typography fontWeight={700} fontSize={10} >{`${h} hr ${m} min ago`}</Typography>
            </Box>
            <Link href={`/submission/${submission.id}`} underline="none">
                <Card>
                    <Grid container justifyContent="space-between" alignItems="center" textAlign="left">
                        <Grid  xs={12}>
                            <Typography level="title-lg" sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                fontSize: 10,
                            }}>{workplaceName}</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="body-md" fontWeight={700} sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                fontStyle: 'italic',
                            }}>{specialtyObj.label}</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="title-lg" fontWeight={700}>{`$${submission.BASE_RATE}/hr`}</Typography>
                        </Grid>
                        <Grid  xs={12}>
                            <Typography level="title-lg" textColor="neutral.500" sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                fontSize: 10,
                            }}>{`${city}, ${submission.WORKPLACE_STATE}`}</Typography>
                        </Grid>

                    </Grid>
                </Card>
            </Link>
        </Box>
    )
}