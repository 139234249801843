import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import SearchIcon from "@mui/icons-material/Search";
import AutocompleteListBox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";

const searchClient = algoliasearch(
  'ENBQHNNMNP',
  '905b873f916f9cd68cf682a3046eab06'
);

export default function AutoComplete({formik}) {
  // (1) Create a React state.
  const [autocompleteState, setAutocompleteState] = useState({});
  useEffect(() => {
    return () => {
      setAutocompleteState({});
    }
  }, []);

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        initialState: {
          query: formik.values.WORKPLACE_NAME
        },
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources({ setQuery, refresh }) {
          return [
            {
              sourceId: "hptls",
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "hospitals",
                      query,
                      params: {
                        hitsPerPage: 5,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                });
              },
              onSelect({ item }) {
                formik.setFieldValue("WORKPLACE_NAME", item.NAME);
                formik.setFieldValue("WORKPLACE_CITY", item.CITY);
                formik.setFieldValue("WORKPLACE_STATE", item.STATE);
                formik.setFieldValue("WORKPLACE_ID", item.objectID);
                setQuery(item.NAME);
                refresh();
              },
            },
          ];
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return (
    <div {...autocomplete.getRootProps({})}>
          <Input
            size="md"
            {...autocomplete.getInputProps({})}
            variant="outlined"
            placeholder="Search..."
            startDecorator={<SearchIcon />}
          />

        <Box mt={1}>
          {autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;
                return (
                  <AutocompleteListBox
                    key={"listBox-" + index}
                    {...autocomplete.getPanelProps({})}
                    sx={{ zIndex: 1000, position: 'absolute', p: 0 }}>
                    {items.map((item) => (
                      <AutocompleteOption
                        sx={{py: 0.5}}
                        key={"listItem-" + item.objectID}
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                      >
                        <div key={"listItem-" + item.objectID + "-div"}>
                          <Typography fontSize={14} fontWeight={600} key={"listItem" + item.objectID + "-name"}>
                            {item.NAME}
                          </Typography>
                          <Typography fontSize={14} fontWeight={500} textColor="neutral.500" key={"listItem" + item.objectID + "-state-city"}>
                            {`${item.CITY}, ${item.STATE}`}
                          </Typography>
                        </div>

                      </AutocompleteOption>
                    ))}
                  </AutocompleteListBox>
                );
            })}
        </Box>
    </div>
  );
}
