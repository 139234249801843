import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubmissionCard from '../submission/SubmissionCard';
import { specialties } from '../../constants';
import Link from "@mui/joy/Link";
import IconButton from '@mui/joy/IconButton';

export default function Row(props) {
    const row = props.row;
    const [open, setOpen] = React.useState(false);
    const specialtyObj = specialties.find(t => t.value === row.SPECIALTY);
    const nurseType = specialtyObj.label;

    const toggle = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    return (
        <>
            <tr>
                <td colSpan={1}>
                <IconButton
                            aria-label="expand row"
                            variant="outlined"
                            color="neutral"
                            size="sm"
                            onClick={() => toggle()}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                </td>
                <td colSpan={2}>
                       
                        <Link href={`/hospitals/${row.WORKPLACE_ID}/about`} underline="always">
                            {row.WORKPLACE_NAME}
                        </Link>
                </td>
                <td colSpan={2}>
                    {row.createdAt.toDate().toLocaleDateString()}
                </td>
                <td colSpan={2}>
                    ${row.BASE_RATE}
                </td>
                <td colSpan={2}>
                    <Link href={`/hospitals/${row.WORKPLACE_ID}/specialties/${specialtyObj.value}`} underline="always">
                        {nurseType}
                    </Link>
                </td>
            </tr>
            <tr sx={{ '& > *': { borderBottom: 'none', borderTop: 'none' } }}>
                <td style={{ height: 0, padding: 0, margin: 0 }} colSpan={9}>
                    {
                        open && (
                            <SubmissionCard data={row} cutoff={props.cutoff} />
                        )
                    }
                </td>
            </tr>
        </>
    )
}