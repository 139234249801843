export const rating_specialties = {
    1: "Terrible",
    2: "Lacking",
    3: "OK",
    4: "Good",
    5: "Outstanding"
}

export const specialties = [
    {
        value: 'nurse-burn',
        label: 'Burn Care'
    },
    {
        value: 'nurse-cardiac',
        label: 'Cardiac',
    },
    {
        value: 'nurse-casemanagement',
        label: 'Case Management'
    },
    {
        value: 'nurse-er',
        label: 'ER',
    },
    {
        value: 'nurse-flight',
        label: 'Flight'
    },
    {
        value: 'nurse-float-accute',
        label: 'Accute Care Float Pool'
    },
    {
        value: 'nurse-float-critical',
        label: 'Critical Care Float Pool'
    },
    {
        value: 'nurse-float-pediatric',
        label: 'Maternal-Child Float Pool'
    },
    {
        value: 'nurse-hospice',
        label: 'Hospice/Pallative Care'
    },
    {
        value: 'nurse-icu',
        label: 'ICU',
    },
    {
        value: 'nurse-stepdown',
        label: "Step Down/PCU"
    },
    {
        value: 'nurse-infection',
        label: "Infection Control"
    },
    {
        value: 'nurse-labor',
        label: 'Labor and Delivery',
    },
    {
        value: 'nurse-medicalsurgical',
        label: 'Medical Surgical',
    },
    {
        value: 'nurse-nicu',
        label: 'NICU/PICU',
    },
    {
        value: 'nurse-nuero',
        label: 'Neuro',
    },
    {
        value: 'nurse-educator',
        label: 'Educator'
    },
    {
        value: 'nurse-manager',
        label: 'Nurse Manager'
    },
    {
        value: 'nurse-oncology',
        label: "Hematology/Oncology",
    },
    {
        value: 'nurse-or',
        label: 'Operating Room',
    },
    {
        value: 'nurse-orthopedic',
        label: 'Orthopedic'
    },
    {
        value: 'nurse-ostomy',
        label: 'Wound/Ostomy',
    },
    {
        value: 'nurse-pacu',
        label: 'PACU',
    },
    {
        value: 'nurse-pediatric',
        label: 'Pediatric',
    },
    {
        value: 'nurse-postpartum',
        label: 'Mother-Baby'
    },
    {
        value: "nurse-psych",
        label: "Psych",
    },
    {
        value: 'nurse-radiology',
        label: 'Interventional Radiology',
    },
    {
        value: 'nurse-transplant',
        label: 'Transplant'
    },
    {
        value: 'nurse-trauma',
        label: 'Trauma Nurse',
    },
    {
        value: "other",
        label: "other"
    }
];

export const states = [
    {
        label: "Alabama",
        twoLetterAbbreviation: "AL"
    },
    {
        label: "Alaska",
        twoLetterAbbreviation: "AK"
    },
    {
        label: "Arizona",
        twoLetterAbbreviation: "AZ"
    },
    {
        label: "Arkansas",
        twoLetterAbbreviation: "AR"
    },
    {
        label: "American Samoa",
        twoLetterAbbreviation: "AS"
    },
    {
        label: "California",
        twoLetterAbbreviation: "CA"
    },
    {
        label: "Colorado",
        twoLetterAbbreviation: "CO"
    },
    {
        label: "Connecticut",
        twoLetterAbbreviation: "CT"
    },
    {
        label: "Delaware",
        twoLetterAbbreviation: "DE"
    },
    {
        label: "District of Columbia",
        twoLetterAbbreviation: "DC"
    },
    {
        label: "Florvaluea",
        twoLetterAbbreviation: "FL"
    },
    {
        label: "Georgia",
        twoLetterAbbreviation: "GA"
    },
    {
        label: "Guam",
        twoLetterAbbreviation: "GU"
    },
    {
        label: "Hawaii",
        twoLetterAbbreviation: "HI"
    },
    {
        label: "valueaho",
        twoLetterAbbreviation: "value"
    },
    {
        label: "Illinois",
        twoLetterAbbreviation: "IL"
    },
    {
        label: "Indiana",
        twoLetterAbbreviation: "IN"
    },
    {
        label: "Iowa",
        twoLetterAbbreviation: "IA"
    },
    {
        label: "Kansas",
        twoLetterAbbreviation: "KS"
    },
    {
        label: "Kentucky",
        twoLetterAbbreviation: "KY"
    },
    {
        label: "Louisiana",
        twoLetterAbbreviation: "LA"
    },
    {
        label: "Maine",
        twoLetterAbbreviation: "ME"
    },
    {
        label: "Maryland",
        twoLetterAbbreviation: "MD"
    },
    {
        label: "Massachusetts",
        twoLetterAbbreviation: "MA"
    },
    {
        label: "Michigan",
        twoLetterAbbreviation: "MI"
    },
    {
        label: "Minnesota",
        twoLetterAbbreviation: "MN"
    },
    {
        label: "Mississippi",
        twoLetterAbbreviation: "MS"
    },
    {
        label: "Missouri",
        twoLetterAbbreviation: "MO"
    },
    {
        label: "Montana",
        twoLetterAbbreviation: "MT"
    },
    {
        label: "Nebraska",
        twoLetterAbbreviation: "NE"
    },
    {
        label: "Nevada",
        twoLetterAbbreviation: "NV"
    },
    {
        label: "New Hampshire",
        twoLetterAbbreviation: "NH"
    },
    {
        label: "New Jersey",
        twoLetterAbbreviation: "NJ"
    },
    {
        label: "New Mexico",
        twoLetterAbbreviation: "NM"
    },
    {
        label: "New York",
        twoLetterAbbreviation: "NY"
    },
    {
        label: "North Carolina",
        twoLetterAbbreviation: "NC"
    },
    {
        label: "North Dakota",
        twoLetterAbbreviation: "ND"
    },
    {
        label: "Northern Mariana Islands",
        twoLetterAbbreviation: "MP"
    },
    {
        label: "Ohio",
        twoLetterAbbreviation: "OH"
    },
    {
        label: "Oklahoma",
        twoLetterAbbreviation: "OK"
    },
    {
        label: "Oregon",
        twoLetterAbbreviation: "OR"
    },
    {
        label: "Pennsylvania",
        twoLetterAbbreviation: "PA"
    },
    {
        label: "Puerto Rico",
        twoLetterAbbreviation: "PR"
    },
    {
        label: "Rhode Island",
        twoLetterAbbreviation: "RI"
    },
    {
        label: "South Carolina",
        twoLetterAbbreviation: "SC"
    },
    {
        label: "South Dakota",
        twoLetterAbbreviation: "SD"
    },
    {
        label: "Tennessee",
        twoLetterAbbreviation: "TN"
    },
    {
        label: "Texas",
        twoLetterAbbreviation: "TX"
    },
    {
        label: "Trust Territories",
        twoLetterAbbreviation: "TT"
    },
    {
        label: "Utah",
        twoLetterAbbreviation: "UT"
    },
    {
        label: "Vermont",
        twoLetterAbbreviation: "VT"
    },
    {
        label: "Virginia",
        twoLetterAbbreviation: "VA"
    },
    {
        label: "Virgin Islands",
        twoLetterAbbreviation: "VI"
    },
    {
        label: "Washington",
        twoLetterAbbreviation: "WA"
    },
    {
        label: "West Virginia",
        twoLetterAbbreviation: "WV"
    },
    {
        label: "Wisconsin",
        twoLetterAbbreviation: "WI"
    },
    {
        label: "Wyoming",
        twoLetterAbbreviation: "WY"
    }
]

export const hospital_ehrs = [
    {
        label: "Epic",
        value: "epic"
    },
    {
        label: "Cerner",
        value: "cerner"
    },
    {
        label: "other",
        value: "other"
    }
]

export const EHR = {
    EPIC: {label: "Epic", value: "epic"},
    CERNER: {label: "Cerner", value: "cerner"},
    OTHER: {label: "other", value: "other"}
}

export const parking_options = [
    {
        label: "On-site",
        value: "on-site"
    },
    {
        label: "Off-site (transport bus required)",
        value: "off-site"
    },
    {
        label: "other",
        value: "other"
    }
]

export const PARKING = {
    ON_SITE: {label: "On-site", value: "on-site"},
    OFF_SITE: {label: "Off-site (transport bus required)", value: "off-site"},
    OTHER: {label: "other", value: "other"}
}

export const level_of_education_options = [
    {label: "BSN", value: "bsn"},
    {label: "ADN", value: "adn"},
    {label: "LPN", value: "lpn"}
]

export const filter_level_of_education_options = [
    {label: "Any", value: "any"},
    {label: "BSN", value: "bsn"},
    {label: "ADN", value: "adn"},
    {label: "LPN", value: "lpn"}
]

export const EDUCATION = {
    ANY: {label: "Any", value: "any"},
    BSN: {label: "BSN", value: "bsn"},
    ADN: {label: "ADN", value: "adn"},
    LPN: {label: "LPN", value: "lpn"}
}

export const differential_options = [
    {label: "$/hr", value: "DOLLAR"},
    {label: "%", value: "PERCENTAGE"}
]


export const shift_options = [
    {label: "Day (7am - 7pm)", value: "day"},
    {label: "Night (7pm - 7am)", value: "night"},
    {label: "Rotating Schedule", value: "rotating"},
    {label: "Other", value: "other"}
]

export const filter_shift_options = [
    {label: "Any", value: "any"},
    {label: "Day (7am - 7pm)", value: "day"},
    {label: "Night (7pm - 7am)", value: "night"},
]

export const SHIFTS = {
    ANY: {label: "Any", value: "any"},
    DAY: {label: "Day (7am - 7pm)", value: "day"},
    NIGHT: {label: "Night (7pm - 7am)", value: "night"},
    ROTATING: {label: "Rotating Schedule", value: "rotating"},
    OTHER: {label: "Other", value: "other"}
}

export const preceptorship_options = [
    {label: "yes", value: true},
    {label: "no", value: false}
]

export const stress_options = [
    {label: "Rarely Stressed", value: 1},
    {label: "Stressed the Minority of Shifts ", value: 2},
    {label: "Stressed About Half of Shifts", value: 3},
    {label: "Stressed The Majority of Shifts", value: 4},
    {label: "Extremely Stressed Every Shift", value: 5},
]

export const ENTITIES = {
    individual: "individual",
    hospital: "hospital"
}

export const years_of_experience_options = [
   {label: "0", value: 0},
   {label: "1", value: 1},
   {label: "2", value: 2},
   {label: "3", value: 3},
   {label: "4", value: 4},
   {label: "5", value: 5},
   {label: "6", value: 6},
   {label: "7", value: 7},
   {label: "8", value: 8},
   {label: "9", value: 9},
   {label: "10", value: 10},
   {label: "11", value: 11},
   {label: "12", value: 12},
   {label: "13", value: 13},
   {label: "14", value: 14},
   {label: "15", value: 15},
   {label: "16", value: 16},
   {label: "17", value: 17},
   {label: "18", value: 18},
   {label: "19", value: 19},
   {label: "20", value: 20},
   {label: "21", value: 21},
   {label: "22", value: 22},
   {label: "23", value: 23},
   {label: "24", value: 24},
   {label: "25+", value: 25},
]
