/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from '@mui/joy/Table';
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Skeleton from "@mui/joy/Skeleton";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Row from "../table/Row";
import { db } from "../../util/firebase";
import { query, orderBy, startAfter, limit, getDocs, endBefore, limitToLast, where, collection, getCountFromServer } from "firebase/firestore";
import CallToAddSubmission from '../general/CallToAddSubmission';
import Stack from "@mui/joy/Stack";
import AddIcon from "@mui/icons-material/Add";
import CardOverflow from "@mui/joy/CardOverflow";
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import { Divider } from '@mui/joy';
import { useViewport } from '../../util/util';

export default function LatestGeneralSubmissionsTable(props) {
    const hospitalId = props.hospitalId;
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [prevSnapshotSize, setPrevSnapshotSize] = useState(0);
    const [submissionIndex, setSubmissionIndex] = useState(0);
    const [nextDisabled, setNextDisabled] = useState(true);
    const rowLimit = 10;
    const {width} = useViewport();
    const breakpoint = 670;

    useEffect(() => {
        const fetchData = async () => {
            let col = query(collection(db, "submissions"), where("WORKPLACE_ID", "==", hospitalId))
            const first = query(collection(db, "submissions"), orderBy("createdAt", 'desc'), limit(rowLimit), where("WORKPLACE_ID", "==", hospitalId));
            try {
                if (!loading) {
                    setLoading(true);
                }
                const res = await getCountFromServer(col);
                setCount(res.data().count);
                const documentSnapshot = await getDocs(first);
                var items = [];
                documentSnapshot.forEach((doc) => {
                    items.push({ key: doc.id, ...doc.data() });
                })
                setList(items);
                setSubmissionIndex(submissionIndex + documentSnapshot.size);
                setPrevSnapshotSize(documentSnapshot.size);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    const showNext = ({ item }) => {
        if (list.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchNextData = async () => {
                try {
                    if (!loading) {
                        setLoading(true);
                    }
                    const next = query(collection(db, "submissions"), orderBy("createdAt", 'desc'), where("WORKPLACE_ID", "==", hospitalId), startAfter(item.createdAt), limit(rowLimit));
                    const documentSnapshot = await getDocs(next);
                    var items = [];
                    documentSnapshot.forEach((doc) => {
                        items.push({ key: doc.id, ...doc.data() });
                    })
                    setList(items);
                    setPage(page + 1);
                    setSubmissionIndex(submissionIndex + documentSnapshot.size);
                    setPrevSnapshotSize(documentSnapshot.size);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                }
            }
            fetchNextData();
        }
    };

    const showPrevious = ({ item }) => {
        if (list.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchPreviousData = async () => {
                try {
                    if (!loading) {
                        setLoading(true);
                    }
                    const next = query(collection(db, "submissions"), orderBy("createdAt", 'desc'), where("WORKPLACE_ID", "==", hospitalId), endBefore(item.createdAt), limitToLast(rowLimit));
                    const documentSnapshot = await getDocs(next);
                    var items = [];
                    documentSnapshot.forEach((doc) => {
                        items.push({ key: doc.id, ...doc.data() });
                    })
                    setList(items);
                    setPage(page - 1);
                    setSubmissionIndex(submissionIndex - prevSnapshotSize);
                    setPrevSnapshotSize(documentSnapshot.size);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                }
            }
            fetchPreviousData();
        }
    };

    useEffect(() => {
        if (count - submissionIndex > 0) {
            setNextDisabled(false);
        } else if (!nextDisabled) {
            setNextDisabled(true);
        }
    }, [count, nextDisabled, submissionIndex])

    return (
            <Card variant="outlined" sx={{p: 0, m: 0}}>
                <CardContent>
                <Table
                size={width > breakpoint ? "lg" : "md"}
                sx={{
                    '& > tbody > tr:nth-of-type(odd) > td, & > tbody > tr:nth-of-type(odd) > th[scope="row"]':
                      {
                        borderBottom: 0,
                      },
                  }}
              >
                    <thead>
                        <tr>
                            <th colSpan={1}/>
                            <th colSpan={2}>
                                    Hospital                            
                            </th>
                            <th colSpan={2}>
                                Date
                            </th>
                            <th colSpan={2}>
                                    Base Rate
                            </th>
                            <th colSpan={2}>
                                    Specialty
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr
                                >
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            </> : list.map((row) => {
                                return (
                                    <Row row={row} key={row.key} />
                                )
                            })
                        }
                    </tbody>
                </Table>
                {count === 0 && <CallToAddSubmission />}
                </CardContent>
                <CardOverflow sx={{ bgcolor: 'background.level1', p: 1 }}>
                <CardActions>
                    <Stack direction="row" divider={<Divider orientation="vertical"/>} justifyContent="center" spacing={2}>
                        <Button color="neutral" variant="outlined" size="sm" startDecorator={<AddIcon />} component="a" href="../../contribute">Contribute</Button>
                        <Button size="sm" sx={{mr: 1}} variant="solid" startDecorator={<KeyboardArrowLeft />} disabled={page === 0} onClick={() => showPrevious({ item: list[0] })} >Prev</Button>
                        <Button size="sm" variant="solid" endDecorator={<KeyboardArrowRight />} disabled={nextDisabled} onClick={() => showNext({ item: list[list.length - 1] })}>Next</Button>
                    </Stack>
                </CardActions>
                </CardOverflow>
                </Card>


    );
}
