import React from "react";
import HeroText from "./HeroText";
import LandingSearchSection from "./LandingSearchSection";

export default function LandingSection() {
    return (
        <>
            <HeroText />
            <LandingSearchSection />
        </>
    )
}