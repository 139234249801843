import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import LinearProgress from "@mui/joy/LinearProgress";
import { EDUCATION, ENTITIES, SHIFTS, specialties } from '../../constants';
import { useParams } from "react-router-dom";
import { useHospital } from "../../util/db";
import DisplayRatings from "../general/DisplayRatings";
import DataFilters from "./DataFilters";
import SpecialtyHeader from "./SpecialtyHeader";
import Button from '@mui/joy/Button';
import ShareUrlModal from "../general/ShareUrlModal";
import IosShareIcon from "@mui/icons-material/IosShare";
import Section from "../general/Section";
import { calculateAverageCompensationDataByYoe, calculateAverageRatings, createScatterPlotData, calculateAverages } from "../../util/statistics";
import SpecialtyBarChart from "./SpecialtyBarChart";
import SpecialtyScatterPlot from "./SpecialtyScatterPlot";
import SpecialtySubmissionsTable from "./SpecialtySubmissionsTable";
import { db } from "../../util/firebase";
import {
    collection,
    getDocs,
    where,
    query,
  } from "firebase/firestore";

function getDisplayValue(data) {
    if (data === undefined || data === null) {
        return "Not enough data";
    } else {
        return "$" + data + "/hr";
    }
}

export default function SpecialtySection(props) {
    let { hospitalId, specialtyId } = useParams();
    const shareSpecialtyUrl = `https://realnurse.info/hospitals/${hospitalId}/specialties/${specialtyId}`
    const shareHospitalUrl = `https://realnurse.info/hospitals/${hospitalId}`
    const hospital = useHospital(hospitalId);
    const [shift, setShift] = useState(SHIFTS.ANY.value);
    const [levelOfEducation, setLevelOfEducation] = useState(EDUCATION.ANY.value);
    const [yearsOfExperienceRange, setYearsOfExperienceRange] = useState([0, 25]);
    const [open, setOpen] = useState(false);
    const [submissionsLoading, setSubmissionsLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        async function fetchData() { 
            if (!submissionsLoading) {
                setSubmissionsLoading(true);
            }
            const maxYearsOfExperience = yearsOfExperienceRange[1] === 25 ? 100 : yearsOfExperienceRange[1];
    
            let dynamicQuery = query(
                collection(db, "hospitals", hospitalId, "specialties", specialtyId, "submissions"),
                where("YEARS_OF_EXPERIENCE", ">=", yearsOfExperienceRange[0]), 
                where("YEARS_OF_EXPERIENCE", "<=", maxYearsOfExperience)
            );
    
            if (shift !== SHIFTS.ANY.value) {
                dynamicQuery = query(dynamicQuery, where("SHIFT", "==", shift));
            }            
            if (levelOfEducation !== EDUCATION.ANY.value) {
                dynamicQuery = query(dynamicQuery, where("LEVEL_OF_EDUCATION", "==", levelOfEducation));
            }
    
            try {
                const querySnapshot = await getDocs(dynamicQuery);
                const submissionsList = [];
                querySnapshot.forEach(doc => {
                    submissionsList.push({id: doc.id, ...doc.data()});
                });
                setSubmissions(submissionsList);
            } catch (error) {
                console.error("Error fetching submissions:", error);
            } finally {
                setSubmissionsLoading(false);
            }
        }
        fetchData();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hospitalId, specialtyId, shift, levelOfEducation, yearsOfExperienceRange]);

    if (submissionsLoading || hospital.status === "loading") {
        return (
            <Container>
            <Section bgColor={props.bgColor}
                size={props.size}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid  xs={12}>
                    <Typography>Loading...</Typography>
                </Grid>
                <Grid  xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
            </Section>
            </Container>
        )
    } else {
        const specialtyName = specialties.find(t => t.value === specialtyId).label;
        const ratings = calculateAverageRatings(submissions);
        const barchartDataset = calculateAverageCompensationDataByYoe(submissions, yearsOfExperienceRange[0], yearsOfExperienceRange[1]);
        const scatterDataset = createScatterPlotData(submissions);
        const averageData = calculateAverages(submissions);
        return (
                <Container>
                    <Section bgColor={props.bgColor}
                size={props.size}>
                        <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareSpecialtyUrl} />
                        <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
                            <Grid xs={12}>
                                <SpecialtyHeader data={hospital.data}
                                specialtyId={specialtyId} 
                                shareUrl={shareHospitalUrl} routePathname={props.routePathname}/>
                            </Grid>
                            <Grid xs={12}>
                                <Typography level="h3">{`${specialtyName} Nurses`}</Typography>
                            </Grid>
                            <Grid>
                            <Button variant="outlined" color="neutral" size="sm" startDecorator={<IosShareIcon />} onClick={() => setOpen(true)}>
                                Share This Specialty
                            </Button>
                            </Grid>
                            <Grid xs={12}>
                                <DataFilters shift={shift} setShift={setShift} 
                                levelOfEducation={levelOfEducation} setLevelOfEducation={setLevelOfEducation}
                                yearsOfExperienceRange={yearsOfExperienceRange} setYearsOfExperienceRange={setYearsOfExperienceRange}
                                />
                            </Grid>    
                            <Grid xs={12}>
                                <Typography level="title-lg">Average Ratings</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <DisplayRatings entity={ENTITIES.hospital} data={ratings} />
                            </Grid>
                            <Grid xs={12}>
                                <Typography level="title-lg">Average Compensation</Typography>
                            </Grid>
                            <Grid>
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Base
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                        {getDisplayValue(averageData.AVERAGE_BASE_RATE)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid>
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Weekend Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                       {getDisplayValue(averageData.AVERAGE_WEEKEND_DIFFERENTIAL_RATE)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid>
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Night Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                    {getDisplayValue(averageData.AVERAGE_NIGHT_DIFFERENTIAL_RATE)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid>
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Charge Nurse Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                    {getDisplayValue(averageData.AVERAGE_CHARGE_NURSE_DIFFERENTIAL_RATE)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12}/>
                            <Grid>
                                <SpecialtyBarChart dataset={barchartDataset} />
                            </Grid>
                            <Grid>
                                <SpecialtyScatterPlot dataset={scatterDataset}/>
                            </Grid>
                            <Grid xs={12}>
                                <Typography level="title-lg">Submissions</Typography>
                            </Grid>
                            <Grid>
                                <SpecialtySubmissionsTable submissions={submissions}/>
                            </Grid>
                        </Grid>
                    </Section>
                </Container>
        );
    }
}