import React from "react";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";

export default function SuccessSection() {
    return (
        <Card variant="outlined" sx={{ maxWidth: 650, mt: 2 }}>
            <Grid container spacing={2}>
                <Grid  xs={12}>
                    <Typography level="h3">Success!</Typography>
                </Grid>
                <Grid  xs={12}>
                    <Typography level="body-sm">Thank you for contributing to a more transparent environment for nurses.</Typography>
                </Grid>
            </Grid>
        </Card>
    );
}