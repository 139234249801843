import * as React from 'react';
import Typography from '@mui/joy/Typography';
import Grid from "@mui/joy/Grid";
import { ENTITIES, specialties } from '../../constants';
import Card from "@mui/joy/Card";
import DisplayRatings from '../general/DisplayRatings';
import { getDifferentialString } from '../../util/util';

export default function SubmissionCard(props) {
    const data = props.data;
    const specialtyObj = specialties.find(t => t.value === data.SPECIALTY);
    const nurseType = specialtyObj.label;
    return (
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center" p={2}>
            <Grid xs={12}>
                <Typography level="title-lg">Background</Typography>
            </Grid>
            <Grid>
                <Card variant="outlined">
                    <Typography level="title-md">Specialty</Typography>
                    <Typography level="body-md">{nurseType}</Typography>
                </Card>
            </Grid>
            <Grid>
                <Card variant="outlined">
                    <Typography level="title-md">Level of Education</Typography>
                    <Typography level="body-md">{String(data.LEVEL_OF_EDUCATION).toUpperCase()}</Typography>
                </Card>
            </Grid>
            <Grid>
                <Card variant="outlined">
                    <Typography level="title-md">Years at Hospital</Typography>
                    <Typography level="body-md">{!data.PROTECTED ? data.YEARS_AT_HOSPITAL : "*protected"}</Typography>
                </Card>
            </Grid>
            <Grid>
                <Card variant="outlined">
                    <Typography level="title-md">Years of Experience</Typography>
                    <Typography level="body-md">{data.YEARS_OF_EXPERIENCE}</Typography>
                </Card>
            </Grid>
            <Grid>
                <Card variant="outlined">
                    <Typography level="title-md">Submission Date</Typography>
                    <Typography level="body-md">{data.createdAt.toDate().toLocaleDateString()}</Typography>
                </Card>
            </Grid>
            <Grid xs={12}>
                <Typography level="title-lg">Ratings</Typography>
            </Grid>
            <Grid>
                <DisplayRatings data={data} entity={ENTITIES.individual} />
            </Grid>
            <Grid xs={12}>
                <Typography level="title-lg">Comp Details</Typography>
            </Grid>

            <Grid xs={12} sm="auto" md="auto">
                <Card variant="outlined">
                    <Typography level="title-md">
                        Base
                    </Typography>
                    <Typography level="body-md">
                        ${data.BASE_RATE}/hr
                    </Typography>
                </Card>
            </Grid>
            <Grid xs={12} sm="auto" md="auto">
                <Card variant="outlined">
                    <Typography level="title-md">
                        Weekend Differential
                    </Typography>
                    <Typography level="body-md">
                        {getDifferentialString(data.WEEKEND_DIFFERENTIAL_RATE, data.WEEKEND_DIFFERENTIAL_METRIC, data.PROTECTED)}
                    </Typography>
                </Card>
            </Grid>
            <Grid xs={12} sm="auto" md="auto">
                <Card variant="outlined">
                    <Typography level="title-md">
                        Night Differential
                    </Typography>
                    <Typography level="body-md">
                        {getDifferentialString(data.NIGHT_DIFFERENTIAL_RATE, data.NIGHT_DIFFERENTIAL_METRIC, data.PROTECTED)}
                    </Typography>
                </Card>
            </Grid>
            <Grid xs={12} sm="auto" md="auto">
                <Card variant="outlined">
                    <Typography level="title-md">
                        Charge Nurse Differential
                    </Typography>
                    <Typography level="body-md">
                        {getDifferentialString(data.CHARGE_NURSE_DIFFERENTIAL_RATE, data.CHARGE_NURSE_DIFFERENTIAL_METRIC, data.PROTECTED)}
                    </Typography>
                </Card>
            </Grid>

        </Grid>
    )
}