import React, { useState } from "react";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import { useAuth } from "../../util/auth";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Alert from "@mui/joy/Alert";
import IconButton from "@mui/joy/IconButton";
import { useFormik } from 'formik';
import AuthSocial from "./AuthSocial";
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "@mui/joy";

export default function SignIn({setPage}, props) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [alert, setAlert] = useState("");

    function signInUser(email, password) {
        auth.signin(email, password).then((user) => {
            setPending(false);
        }).catch((error) => {
            setPending(false);
            console.log(error);
            setAlert(error.message)
        });
    }

    const validationSchema = yup.object({
        EMAIL: yup.string().email("Not a valid email").required("Email is required"),
        PASSWORD: yup.string().required("Password is required")
    })

    const formik = useFormik({
        initialValues: {
            EMAIL: "",
            PASSWORD: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setPending(true);
            signInUser(values.EMAIL, values.PASSWORD);
        }
    })


    return (

                <Grid container={true} spacing={2} justifyContent="center" alignItems="center">
                    <Grid  xs={12} textAlign="center">
                        <Typography level="h2">Sign In</Typography>
                    </Grid>
                    <Grid  xs={12} textAlign="center">
                        <Typography>to continue to realnurse</Typography>
                    </Grid>
                    {alert.length > 0 &&
                        <Grid  xs={12}>
                            <Alert
                                variant="outlined"
                                color="danger"
                                endDecorator={<IconButton onClick={() => setAlert("")}><CloseIcon /></IconButton>}>
                                {alert}
                            </Alert>
                        </Grid>}
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.EMAIL)}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                size="lg"
                                variant="outlined"
                                type="email"
                                label="Email"
                                name="EMAIL"
                                value={formik.values.EMAIL}
                                onChange={formik.handleChange}
                                placeholder="user@example.com"
                                fullWidth={true}
                            />
                            {Boolean(formik.errors.EMAIL) && <FormHelperText>{formik.errors.EMAIL}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.PASSWORD)}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                size="lg"
                                variant="outlined"
                                type="password"
                                label="Password"
                                name="PASSWORD"
                                value={formik.values.PASSWORD}
                                onChange={formik.handleChange}
                                fullWidth={true}
                            />
                            {Boolean(formik.errors.PASSWORD) && <FormHelperText>{formik.errors.PASSWORD}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid  xs={12}>
                        <Button
                            onClick={formik.submitForm}
                            color="success"
                            size="lg"
                            type="submit"
                            disabled={pending || Boolean(formik.errors.EMAIL) || Boolean(formik.errors.PASSWORD)}
                            fullWidth={true}
                            startDecorator={pending && <CircularProgress />}
                        >
                            {!pending && <span>Sign In</span>}
                        </Button>
                    </Grid>
                    <Grid  xs={12} textAlign="center">
                        <Typography>Or</Typography>
                    </Grid>
                    <Grid  xs={12}>
                        <AuthSocial
                            buttonAction="Sign In"
                            providers={props.providers}
                            showLastUsed={true}
                        />
                    </Grid>
                    <Grid  mt={2}>
                        <Box display="inline-block">
                            <Link onClick={() => setPage("signup")} underline="always" pr={3}>Sign Up</Link>
                            <Link onClick={() => setPage("forgotpassword")} underline="always">Forgot Password</Link>
                        </Box>
                    </Grid>
                </Grid>
    );
}