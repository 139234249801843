import React, { useEffect } from "react";
import Input from '@mui/joy/Input';
import Switch from '@mui/joy/Switch';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import { getIn } from 'formik';
import SelectHospital from "../search/SelectHospital";
import { states, parking_options, hospital_ehrs } from "../../constants";

export default function HospitalInfoSection({ formik, checked, setChecked }) {

    const handleSelectStateChange = (event, newValue) => {
        formik.setFieldValue("WORKPLACE_STATE", newValue);
    };

    const handleSelectParkingChange = (event, newValue) => {
        formik.setFieldValue("PARKING", newValue);
    };

    const handleSelectEhrChange = (event, newValue) => {
        formik.setFieldValue("EHR", newValue);
    };

    return (
        <Card variant="outlined" sx={{ maxWidth: 650 }}>
            <Grid container spacing={2}>
                <Grid  xs={12}>
                    <Typography level="h4">Hospital Info</Typography>
                </Grid>
                {!checked && <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.WORKPLACE_NAME) && formik.touched.WORKPLACE_NAME} required>
                        <FormLabel>Select Hospital</FormLabel>
                        <SelectHospital formik={formik} />
                        {(Boolean(formik.errors.WORKPLACE_NAME) && formik.touched.WORKPLACE_NAME) &&
                            <FormHelperText>{formik.errors.WORKPLACE_NAME}</FormHelperText>}
                    </FormControl>
                </Grid>}
                {checked &&
                    <>
                        <Grid  xs={12} sm={9} md={6}>
                            <FormControl error={Boolean(formik.errors.WORKPLACE_NAME) && formik.touched.WORKPLACE_NAME}  required>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    placeholder="Enter workplace name"
                                    id="workplace-name"
                                    name="WORKPLACE_NAME"
                                    label="Name"
                                    type="text"
                                    value={formik.values.WORKPLACE_NAME}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {(Boolean(formik.errors.WORKPLACE_NAME) && formik.touched.WORKPLACE_NAME) &&
                            <FormHelperText>{formik.errors.WORKPLACE_NAME}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid  xs={12} sm={9} md={6}>
                            <FormControl error={Boolean(formik.errors.WORKPLACE_CITY) && formik.touched.WORKPLACE_CITY} required>
                                <FormLabel>City</FormLabel>
                                <Input
                                    placeholder="Enter workplace city"
                                    id="workplace-city"
                                    name="WORKPLACE_CITY"
                                    label="City"
                                    type="text"
                                    value={formik.values.WORKPLACE_CITY}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {(Boolean(formik.errors.WORKPLACE_CITY) && formik.touched.WORKPLACE_CITY) &&
                                    <FormHelperText>{formik.errors.WORKPLACE_CITY}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid  xs={12} sm={9} md={6}>
                            <FormControl error={Boolean(formik.errors.WORKPLACE_STATE) && formik.touched.WORKPLACE_STATE} required>
                                <FormLabel>State</FormLabel>
                                <Select onChange={handleSelectStateChange} id="select-state" name="WORKPLACE_STATE" value={formik.values.WORKPLACE_STATE} placeholder="Select a state...">
                                    {states.map((state) => {
                                        return (
                                            <Option key={state.twoLetterAbbreviation} value={state.twoLetterAbbreviation}>{state.name}</Option>
                                        );
                                    })}
                                </Select>
                                {(Boolean(formik.errors.WORKPLACE_STATE) && formik.touched.WORKPLACE_STATE) &&
                                    <FormHelperText>{getIn(formik.errors, "WORKPLACE.STATE")}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </>
                }
                <Grid  xs={12}>
                    <FormControl
                        orientation="horizontal"
                        sx={{ width: 300, justifyContent: 'space-between' }}
                    >
                        <div>
                            <FormLabel>Can't find your hospital?</FormLabel>
                            <FormHelperText sx={{ mt: 0 }}>Turn on manual entry mode</FormHelperText>
                        </div>
                        <Switch
                            id="toggle-manual-input"
                            checked={checked}
                            onChange={(event) => setChecked(event.target.checked)}
                            color={checked ? 'success' : 'neutral'}
                            variant={checked ? 'solid' : 'outlined'}
                            endDecorator={checked ? 'On' : 'Off'}
                            slotProps={{
                                endDecorator: {
                                    sx: {
                                        minWidth: 24,
                                    },
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.EHR) && formik.touched.EHR} required>
                        <FormLabel>EHR/EMR System</FormLabel>
                        <Select
                            onChange={handleSelectEhrChange}
                            id="select-ehr"
                            name="EHR"
                            value={formik.values.EHR}
                            placeholder="Please select one">
                            {hospital_ehrs.map((option) => {
                                return (
                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.EHR && formik.touched.EHR) &&
                            <FormHelperText>{formik.errors.EHR}</FormHelperText>}
                    </FormControl>
                </Grid>
                {formik.values.EHR === "other" &&
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.OTHER_EHR) && formik.touched.OTHER_EHR} required>
                            <FormLabel>Enter EHR/EMR System</FormLabel>
                            <Input
                                placeholder="Enter EHR/EMR System"
                                id="enter-other-ehr"
                                name="OTHER_EHR"
                                label="Other EMR/EHR System"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {(Boolean(formik.errors.OTHER_EHR) && formik.touched.OTHER_EHR) &&
                                <FormHelperText>{formik.errors.OTHER_EHR}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.PARKING) && formik.touched.PARKING} required>
                        <FormLabel>Parking for Nurses</FormLabel>
                        <Select
                            onChange={handleSelectParkingChange}
                            id="select-parking"
                            name="PARKING"
                            value={formik.values.PARKING}
                            placeholder="Please select one">
                            {parking_options.map((option) => {
                                return (
                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.PARKING && formik.touched.PARKING) &&
                            <FormHelperText>{formik.errors.PARKING}</FormHelperText>}
                    </FormControl>
                </Grid>
                {formik.values.PARKING === "other" &&
                    <Grid  xs={12}>
                        <FormControl error={Boolean(formik.errors.OTHER_PARKING) && formik.touched.OTHER_PARKING} required>
                            <FormLabel>Enter Parking</FormLabel>
                            <Input
                                placeholder="Enter Parking"
                                id="enter-other-parking"
                                name="OTHER_PARKING"
                                label="Other Parking System"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {(Boolean(formik.errors.OTHER_PARKING) && formik.touched.OTHER_PARKING) &&
                                <FormHelperText>{formik.errors.OTHER_PARKING}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }
                
            </Grid>
        </Card>
    )
}