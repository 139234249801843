import React from "react";
import Meta from "../components/general/Meta";
import ContributeSection from "../components/contribute/ContributeSection";

function ContributePage(props) {
  return (
    <>
      <Meta title="Submit" />
      <ContributeSection bgColor="white"
        size="small" />
    </>
  );
}

export default ContributePage;
