import React from "react";
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Box from '@mui/joy/Box';
import { Star, StarBorder } from "@mui/icons-material";
import Typography from "@mui/joy/Typography";
import Chip from '@mui/joy/Chip';
import { rating_specialties } from "../../constants";

function getChipColor(value) {
    if (value === undefined || value === null) {
        return "nuetral";
    } else if (value <= 2.25) {
        return "danger";
    } else if (value <= 3.5) {
        return "warning";
    } else {
        return "success";
    }
}

function getRatingLabel(value) {
    if (value < 1.5) {
        return rating_specialties[1];
    } else if (value < 2.5) {
        return rating_specialties[2];
    } else if (value < 3.5) {
        return rating_specialties[3];
    } else if (value < 4.5) {
        return rating_specialties[4];
    } else {
        return rating_specialties[5];
    }
}

export default function ReadOnlyStarRating({ value, title }) {
    return (
        <Box>
            <Typography mb={1} level="body-md" 
            endDecorator={value !== undefined && <Chip component="span" size="sm" color={getChipColor(value)}>{value !== null ? getRatingLabel(value) : "No rating yet"}</Chip>}>
                {title}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: "center" }}>
            <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row', minWidth: 150 }}
                variant="outlined"
            >
                <Box
                    key={1}
                    sx={(theme) => ({
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 30,
                        '&:not([data-first-child])': {
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        },
                        [`&[data-first-child] .${radioClasses.action}`]: {
                            borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                        [`&[data-last-child] .${radioClasses.action}`]: {
                            borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                    })}
                >
                    <Radio
                        value={1}
                        disableIcon
                        overlay
                        label={value >= 1 ? <Star fontSize="14"/> : <StarBorder fontSize="14"/>}
                        variant={value >= 1 ? "solid" : "plain"}
                        color={getChipColor(value)}
                        slotProps={{
                            input: { 'aria-label': 1 },
                            action: {
                                sx: { borderRadius: 0, transition: 'none' },
                            },
                            label: { sx: { lineHeight: 0 } },
                        }}
                    />
                </Box>
                <Box
                    key={2}
                    sx={(theme) => ({
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 30,
                        '&:not([data-first-child])': {
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        },
                        [`&[data-first-child] .${radioClasses.action}`]: {
                            borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                        [`&[data-last-child] .${radioClasses.action}`]: {
                            borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                    })}
                >
                    <Radio
                        value={2}
                        disableIcon
                        overlay
                        label={value >= 2 ?<Star fontSize="14"/> : <StarBorder fontSize="14"/>}
                        variant={value >= 2 ? "solid" : "plain"}
                        color={getChipColor(value)}
                        slotProps={{
                            input: { 'aria-label': 1 },
                            action: {
                                sx: { borderRadius: 0, transition: 'none' },
                            },
                            label: { sx: { lineHeight: 0 } },
                        }}
                    />
                </Box>
                <Box
                    key={3}
                    sx={(theme) => ({
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 30,
                        '&:not([data-first-child])': {
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        },
                        [`&[data-first-child] .${radioClasses.action}`]: {
                            borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                        [`&[data-last-child] .${radioClasses.action}`]: {
                            borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                    })}
                >
                    <Radio
                        value={3}
                        disableIcon
                        overlay
                        label={value >= 3 ?<Star fontSize="14"/> : <StarBorder fontSize="14"/>}
                        variant={value >= 3 ? "solid" : "plain"}
                        color={getChipColor(value)}
                        slotProps={{
                            input: { 'aria-label': 1 },
                            action: {
                                sx: { borderRadius: 0, transition: 'none' },
                            },
                            label: { sx: { lineHeight: 0 } },
                        }}
                    />
                </Box>
                <Box
                    key={4}
                    sx={(theme) => ({
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 30,
                        '&:not([data-first-child])': {
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        },
                        [`&[data-first-child] .${radioClasses.action}`]: {
                            borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                        [`&[data-last-child] .${radioClasses.action}`]: {
                            borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                    })}
                >
                    <Radio
                        value={4}
                        disableIcon
                        overlay
                        label={value >= 4 ?<Star fontSize="14"/> : <StarBorder fontSize="14"/>}
                        variant={value >= 4 ? "solid" : "plain"}
                        color={getChipColor(value)}
                        slotProps={{
                            input: { 'aria-label': 1 },
                            action: {
                                sx: { borderRadius: 0, transition: 'none' },
                            },
                            label: { sx: { lineHeight: 0 } },
                        }}
                    />
                </Box>
                <Box
                    key={5}
                    sx={(theme) => ({
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 30,
                        '&:not([data-first-child])': {
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        },
                        [`&[data-first-child] .${radioClasses.action}`]: {
                            borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                        [`&[data-last-child] .${radioClasses.action}`]: {
                            borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                        },
                    })}
                >
                    <Radio
                        value={5}
                        disableIcon
                        overlay
                        label={value >= 5 ?<Star fontSize="14"/> : <StarBorder fontSize="14"/>}
                        variant={value >= 5 ? "solid" : "plain"}
                        color={getChipColor(value)}
                        slotProps={{
                            input: { 'aria-label': 1 },
                            action: {
                                sx: { borderRadius: 0, transition: 'none' },
                            },
                            label: { sx: { lineHeight: 0 } },
                        }}
                    />
                </Box>
            </RadioGroup>
            <Typography ml={1} level="title-md">{value}/5</Typography>
            </Box>
        </Box>
    )
}