import React from "react";
import Grid from "@mui/joy/Grid";
import ReadOnlyStarRating from "../general/ReadOnlyStarRating";
import { ENTITIES } from "../../constants";
import StressLevelRating from "../general/StressLevelRating";

export default function DisplayRatings({data, entity}) {
    if (entity === ENTITIES.individual) {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.RN_TO_PATIENT_RATIO_RATING} title="RN to Patient Ratio"/>
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.HOSPITAL_MANAGEMENT_RATING} title="Hospital Management" />
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.NURSING_SUPPORT_RATING} title="Nursing Support" />
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.ONBOARDING_RATING} title="Onboarding Experience"/>
                </Grid>
                <Grid xs={12} sm="auto">
                    <StressLevelRating value={data.STRESS_LEVEL} title="Reported Stress Level"/>
                </Grid>
            </Grid>
    )
    }
    if (entity === ENTITIES.hospital) {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.AVERAGE_RN_TO_PATIENT_RATIO_RATING} title="RN to Patient Ratio"/>
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.AVERAGE_HOSPITAL_MANAGEMENT_RATING} title="Hospital Management" />
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.AVERAGE_NURSING_SUPPORT_RATING} title="Nursing Support" />
                </Grid>
                <Grid  xs={12} sm="auto">
                    <ReadOnlyStarRating value={data.AVERAGE_ONBOARDING_RATING} title="Onboarding Experience"/>
                </Grid>
                <Grid xs={12} sm="auto">
                    <StressLevelRating value={data.AVERAGE_STRESS_LEVEL_RATING} title="Reported Stress Level"/>
                </Grid>
            </Grid>
    )
    }
   
}