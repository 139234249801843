import React, { useState } from "react";
import Typography from '@mui/joy/Typography';
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import Button from '@mui/joy/Button';
import TabBar from "./HospitalTabBar";
import Skeleton from "@mui/joy/Skeleton";
import IosShareIcon from '@mui/icons-material/IosShare';
import Card from '@mui/joy/Card';
import ShareUrlModal from "../general/ShareUrlModal";

export default function HospitalHeader({ data, status, shareUrl, routePathname }) {

    const [open, setOpen] = useState(false);

    if (status === "success") {
        return (
            <Box>
                <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareUrl} />
                <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid  xs={12}>
                        <Typography level="h2" fontWeight={700}>
                            {data.NAME}
                        </Typography>
                    </Grid>
                    <Grid  md='auto'>
                        <Typography level="h4" textColor="neutral.400">
                            {`${data.CITY}, ${data.STATE}`}
                        </Typography>
                    </Grid>
                    <Grid >
                        <Button variant="outlined" color="neutral" size="sm" startDecorator={<IosShareIcon />} onClick={() => setOpen(true)}>
                            Share This Hospital
                        </Button>
                    </Grid>
                    <Grid  xs={12}>
                        <TabBar routePathname={routePathname} hospitalId={data.id} />
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (status === "loading" || status === "idle") {
        return (
            <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row">
                    <div>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                            <Skeleton />
                        </Typography>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Grid >
                                <Skeleton width={100} />
                            </Grid>
                            <Grid >
                                <Skeleton width={70} />
                            </Grid>
                            <Grid >
                                <Skeleton width={70} />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Skeleton width={100} />
            </Card>
        )
    }
}