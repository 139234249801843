import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Button from '@mui/joy/Button';
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Sheet from '@mui/joy/Sheet';
import Typography from "@mui/joy/Typography";
import { makeStyles } from "@mui/styles";
import Section from "./Section";
import { Link, useRouter } from "../../util/router";
import LinkMui from "@mui/material/Link";
import SearchIcon from '@mui/icons-material/Search';
import { useViewport } from "../../util/util";
import Divider from "@mui/joy/Divider";
import { Add } from "@mui/icons-material";
import LandingSearchModal from "../search/LandingSearchModal";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 40,
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigate, setNavigate] = useState(false);
  const [workplaceId, setWorkplaceId] = useState("");
  const router = useRouter();
  const logo = props.logo;
  const { width } = useViewport();
  const breakpoint = 820;

  useEffect(() => {
    if (!open && navigate && workplaceId !== "") {
      router.push(`/hospitals/${workplaceId}/about`);
      return () => {
        setNavigate(false);
        setWorkplaceId("");
      }
    }
  }, [open, navigate, router, workplaceId]);


  const SearchButton = () => {
    // The width below which the mobile view should be rendered

    if (width > breakpoint) {
      return (
        <Button onClick={() => setOpen(true)} startDecorator={<SearchIcon />} variant="outlined" color="neutral" size="lg" 
        sx={{minWidth: 300, justifyContent: "left", alignItems: "center"}}>Search Hospitals...</Button>
      );
    } else {
      return (
        <IconButton onClick={() => setOpen(true)}
          variant="outlined" sx={{ mr: 2 }}
          size="sm">
          <SearchIcon />
        </IconButton>
      )
    }
  };

  const ContributeButton = () => {
    if (width > breakpoint) {
      return (
        <Button component="a" sx={{ py: 0 }} aria-label="sign up" href="/contribute" color="primary" variant="solid" endDecorator={<Add />} size="lg">
          Contribute
        </Button>
      );
    } else {
      return (
        <Button component="a" sx={{ py: 0 }} aria-label="sign up" href="/contribute" color="primary" variant="solid" endDecorator={<Add />} size="sm">
          Contribute
        </Button>
      )
    }
  };

  const handleWorkplaceIdChange = (id) => {
    setWorkplaceId(id);
    setNavigate(true);
  }

  return (
    <Section size="auto">
      <Container>
        <Sheet sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", py: 1 }}>
          <LinkMui to="/" component={Link} underline="none" mr={4}>
            <Box display="inline-flex" alignItems="center">
              <img src={logo} alt="Logo" className={classes.logo} />
              <Typography ml={2} level="h4" color="primary">
                Real Nurse
              </Typography>
            </Box>
          </LinkMui>

          {width <= breakpoint && <div className={classes.spacer} />}
          {props.showSearch &&
            <>
              <SearchButton />
              <LandingSearchModal open={open} setOpen={setOpen} handleWorkplaceIdChange={handleWorkplaceIdChange} />
            </>
          }
          {width > breakpoint && <div className={classes.spacer} />}
          <ContributeButton />
        </Sheet>
      </Container>
      <Divider />
    </Section >
  );
}

export default Navbar;
