import React from "react";
import Meta from "../components/general/Meta";
import DashboardSection from "../components/dashboard/DashboardSection";
import {requireAuth} from "../util/auth";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Admin Dashboard" />
      <DashboardSection
        bgColor="light"
        size="medium"
        routePathname={props.routePathname}
      />
    </>
  );
}

export default requireAuth(DashboardPage);
