import React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Select from '@mui/joy/Select';
import Option from "@mui/joy/Option";
import { differential_options, preceptorship_options } from "../../constants";

export default function CompensationSection({ formik }) {

    const handleSelectPreceptorshipChange = (event, newValue) => {
        formik.setFieldValue("HAS_PAID_PRECEPTORSHIP", newValue);
    };

    const handleSelectWeekendDifferentialMetric = (event, newValue) => {
        formik.setFieldValue("WEEKEND_DIFFERENTIAL_METRIC", newValue);
    }

    const handleSelectNightDifferentialMetric = (event, newValue) => {
        formik.setFieldValue("NIGHT_DIFFERENTIAL_METRIC", newValue);
    }

    const handleSelectChargeNurseDifferentialMetric = (event, newValue) => {
        formik.setFieldValue("CHARGE_NURSE_DIFFERENTIAL_METRIC", newValue);
    }

    return (
        <Card variant="outlined" sx={{ maxWidth: 650 }}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Typography level="h4">Compensation Details</Typography>
                </Grid>
                <Grid xs={12}>
                    <FormControl error={Boolean(formik.errors.BASE_RATE) && formik.touched.BASE_RATE} required>
                        <FormLabel>Base Rate</FormLabel>
                        <Input
                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                            placeholder="XX"
                            id="base-rate"
                            name="BASE_RATE"
                            label="Base Rate"
                            type="number"
                            value={formik.values.BASE_RATE}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            startDecorator={<AttachMoney />}
                            endDecorator={<Typography level="body-md">/hr</Typography>}
                        />
                        {(Boolean(formik.errors.BASE_RATE) && formik.touched.BASE_RATE) &&
                            <FormHelperText>{formik.errors.BASE_RATE}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl error={Boolean(formik.errors.WEEKEND_DIFFERENTIAL_RATE) && formik.touched.WEEKEND_DIFFERENTIAL_RATE} required>
                        <FormLabel>Weekend Differential</FormLabel>
                        <Input
                            size="sm"
                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                            placeholder="XX"
                            id="weekend-differential-rate"
                            name="WEEKEND_DIFFERENTIAL_RATE"
                            label="Weekend Differential Rate"
                            type="number"
                            value={formik.values.WEEKEND_DIFFERENTIAL_RATE}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            startDecorator={<Select
                                variant="plain"
                                size="sm"
                                onChange={handleSelectWeekendDifferentialMetric}
                                id="select-weekend-differential-metric"
                                name="WEEKEND_DIFFERENTIAL_METRIC"
                                value={formik.values.WEEKEND_DIFFERENTIAL_METRIC}
                            >
                                {differential_options.map((option) => {
                                    return (
                                        <Option key={option.value} value={option.value}>{option.label}</Option>
                                    );
                                })}
                            </Select>}
                        />
                        {(Boolean(formik.errors.WEEKEND_DIFFERENTIAL_RATE) && formik.touched.WEEKEND_DIFFERENTIAL_RATE) &&
                            <FormHelperText>{formik.errors.WEEKEND_DIFFERENTIAL_RATE}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl error={Boolean(formik.errors.NIGHT_DIFFERENTIAL_RATE) && formik.touched.NIGHT_DIFFERENTIAL_RATE} required>
                        <FormLabel>Night Differential</FormLabel>
                        <Input
                            size="sm"
                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                            placeholder="XX"
                            id="night-differential-rate"
                            name="NIGHT_DIFFERENTIAL_RATE"
                            label="Night Differential Rate"
                            type="number"
                            value={formik.values.NIGHT_DIFFERENTIAL_RATE}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            startDecorator={<Select
                                variant="plain"
                                size="sm"
                                onChange={handleSelectNightDifferentialMetric}
                                id="select-night-differential-metric"
                                name="NIGHT_DIFFERENTIAL_METRIC"
                                value={formik.values.NIGHT_DIFFERENTIAL_METRIC}
                            >
                                {differential_options.map((option) => {
                                    return (
                                        <Option key={option.value} value={option.value}>{option.label}</Option>
                                    );
                                })}
                            </Select>}
                        />
                        {(Boolean(formik.errors.NIGHT_DIFFERENTIAL_RATE) && formik.touched.NIGHT_DIFFERENTIAL_RATE) &&
                            <FormHelperText>{formik.errors.NIGHT_DIFFERENTIAL_RATE}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl error={Boolean(formik.errors.CHARGE_NURSE_DIFFERENTIAL_RATE) && formik.touched.CHARGE_NURSE_DIFFERENTIAL_RATE} required>
                        <FormLabel sx={{ fontSize: 12 }}>Charge Nurse Differential</FormLabel>
                        <Input
                            size="sm"
                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                            placeholder="XX"
                            id="charge-nurse-differential-rate"
                            name="CHARGE_NURSE_DIFFERENTIAL_RATE"
                            label="Charge Nurse Differential Rate"
                            type="number"
                            value={formik.values.CHARGE_NURSE_DIFFERENTIAL_RATE}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            startDecorator={<Select
                                variant="plain"
                                size="sm"
                                onChange={handleSelectChargeNurseDifferentialMetric}
                                id="select-charge-nurse-differential-metric"
                                name="CHARGE_NURSE_DIFFERENTIAL_METRIC"
                                value={formik.values.CHARGE_NURSE_DIFFERENTIAL_METRIC}
                            >
                                {differential_options.map((option) => {
                                    return (
                                        <Option key={option.value} value={option.value}>{option.label}</Option>
                                    );
                                })}
                            </Select>}
                        />
                        {(Boolean(formik.errors.CHARGE_NURSE_DIFFERENTIAL_RATE) && formik.touched.CHARGE_NURSE_DIFFERENTIAL_RATE) &&
                            <FormHelperText>{formik.errors.CHARGE_NURSE_DIFFERENTIAL_RATE}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl error={Boolean(formik.errors.HAS_PAID_PRECEPTORSHIP) && formik.touched.HAS_PAID_PRECEPTORSHIP} required>
                        <FormLabel>Paid Preceptorship</FormLabel>
                        <Select onChange={handleSelectPreceptorshipChange} id="select-has-paid-preceptorship" name="HAS_PAID_PRECEPTORSHIP"
                            value={formik.values.HAS_PAID_PRECEPTORSHIP}
                            placeholder="Please select one">
                            {preceptorship_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.HAS_PAID_PRECEPTORSHIP && formik.touched.HAS_PAID_PRECEPTORSHIP) && <FormHelperText>{formik.errors.HAS_PAID_PRECEPTORSHIP}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
} 