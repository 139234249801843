import React from "react";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { useRecentSubmissions } from "../../util/db";
import RecentSubmissionCard from "./RecentSubmissionCard"

export default function RecentSubmissionSection(props) {
    const { data, status, error } = useRecentSubmissions();
    if (status === "success") {
        return (
                <Container>
                    <Grid container justifyContent="center" alignItems="center" spacing={1.5}>
                        <Grid  xs={12} >
                            <Typography level="h1">Real Transparency</Typography>
                        </Grid>
                        {
                            data.map((submission) => {
                                return (
                                    <Grid  key={submission.id} xs={6} sm={3}>
                                        <RecentSubmissionCard submission={submission} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Container>

        )
    } else if (status === "loading" || status === "idle") {
        return (

            <Grid container justifyContent="center" alignItems="center" spacing={2} my={2}>
                <Grid >
                    <CircularProgress size={25} />
                </Grid>
                <Grid >
                    <Typography variant="subtitle2">Loading Recent Submissions...</Typography>
                </Grid>
            </Grid>
        )
    } else {

        <Grid container justifyContent="center" alignItems="center">
            <Grid >
                <Typography color="error">Error</Typography>
            </Grid>
            <Grid >
                <Typography color="error">{error}</Typography>
            </Grid>
        </Grid>
    }
}