import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import "./LandingSection.css";
import Section from "../general/Section";
import { Input } from "@mui/joy";
import LandingSearchModal from "../search/LandingSearchModal";
import { Search } from "@mui/icons-material";

export default function LandingSearchSection() {
    const [open, setOpen] = useState(false);

    return (
        <Section
            size="small"
        >
            <Container>
                <LandingSearchModal open={open} setOpen={setOpen}/>
                <Grid container spacing={2} textAlign="left">
                    <Grid  xs={12}>
                        <Typography level="h4">
                            See what nurses are saying about your hospital
                        </Typography>
                    </Grid>
                    <Grid xs={12} mb={6}>
                        <Input startDecorator={<Search/>} 
                        placeholder="Search for your hospital..." 
                        onClick={() => setOpen(true)} 
                        onMouseDown={(event) => {
                            event.preventDefault();
                        }} 
                        size="lg"
                        />
                    </Grid>
                </Grid>
            </Container>
        </Section>
    )
}