import React, { useState } from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Slider from '@mui/joy/Slider';
import {  years_of_experience_options, filter_shift_options, filter_level_of_education_options } from "../../constants";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Card } from "@mui/joy";

export default function DataFilters({ shift, setShift, 
    levelOfEducation, setLevelOfEducation, 
    yearsOfExperienceRange, setYearsOfExperienceRange
    }) {

    const [slider, setSlider] = useState(yearsOfExperienceRange);

    const handleShiftChange = (event, newValue) => {
        setShift(newValue);
    };

    const handleLevelOfEducationChange = (event, newValue) => {
        setLevelOfEducation(newValue);
    };

   const handleYearsOfExperienceRangeChange = (event, newValue) => {
        setYearsOfExperienceRange(newValue);
   }

   const handleYoeUiChange = (event, newValue) => {
    setSlider(newValue);
}

   function valueText(value) {
    return `${value}`;
  }

  const handleMinYoeChange = (event, newValue) => {
    const newRange = yearsOfExperienceRange.map((val, index) => {
        if (index === 0) {
            return newValue;
        } else {
            return val;
        }
    });
    setYearsOfExperienceRange(newRange);
  }

  const handleMaxYoeChange = (event, newValue) => {
    const newRange = yearsOfExperienceRange.map((val, index) => {
        if (index === 1) {
            return newValue;
        } else {
            return val;
        }
    });
    setYearsOfExperienceRange(newRange);
  }


  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
        value: 15,
        label: '15',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 25,
        label: '25+',
    },
  ];
  


    return (
        <Card>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Typography level="title-lg" endDecorator={<FilterListIcon/>}>Data Filters</Typography>
                </Grid>
                 <Grid>
                    <FormControl>
                        <FormLabel>Level of Education</FormLabel>
                        <Select onChange={handleLevelOfEducationChange} id="select-level-of-education" value={levelOfEducation} placeholder="Please select one">
                            {filter_level_of_education_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl>
                        <FormLabel>Shift</FormLabel>
                        <Select onChange={handleShiftChange} id="select-shift-option" value={shift} placeholder="Please select one">
                            {filter_shift_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <Typography>Years of Experience</Typography>
                </Grid>
                <Grid>
                <FormControl>
                        <FormLabel>Min Years</FormLabel>
                        <Select onChange={handleMinYoeChange} id="select-minimum-years" value={yearsOfExperienceRange[0]}>
                            {years_of_experience_options.map((option) => {
                                return (
                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                <FormControl>
                        <FormLabel>Max Years</FormLabel>
                        <Select onChange={handleMaxYoeChange} id="select-max-years" value={yearsOfExperienceRange[1]}>
                            {years_of_experience_options.map((option) => {
                                return (
                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={6}>
                <Typography level="title-sm">Years of Experience Range</Typography>
                <Slider
                    value={slider}
                    onChange={handleYoeUiChange}
                    onChangeCommitted={handleYearsOfExperienceRangeChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valueText}
                    marks={marks}
                    max={25}
                    min={0}
                    step={1}
      />
                </Grid>
            </Grid>
            </Card>
    )
}