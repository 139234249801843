import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Section from "../general/Section";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import { ENTITIES, specialties } from '../../constants';
import DisplayRatings from "../general/DisplayRatings";
import Card from "@mui/joy/Card";
import { useParams } from "react-router-dom";
import { db } from "../../util/firebase";
import { doc, getDoc } from "firebase/firestore";
import Button from "@mui/joy/Button";
import IosShareIcon from '@mui/icons-material/IosShare';
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import ShareUrlModal from "../general/ShareUrlModal";
import { getDifferentialString } from "../../util/util";

export default function SubmissionSection(props) {

    const { submissionId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const shareUrl = `https://realnurse.info/submission/${submissionId}`;

    useEffect(() => {
        const fetchData = async () => {
            const submissionDoc = doc(db, "submissions", submissionId);
            try {
                if (!loading) {
                    setLoading(true);
                }
                const submission = await getDoc(submissionDoc);
                setData(submission.data());
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return (
            <Section size={props.size} bgColor={props.bgColor}>
                <Container>
                <LinearProgress>Loading Submission...</LinearProgress>
                </Container>
            </Section>
        )
    } else {
        const specialtyObj = specialties.find(t => t.value === data.SPECIALTY);
        const nurseType = specialtyObj.label;
        return (
            <Section
                size={props.size}
                bgColor={props.bgColor}
                bgImage={props.bgImage}
                bgImageOpacity={props.bgImageOpacity}
            >
                <Container>
                    <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareUrl} />
                    <Box sx={{ px: 1, py: 3 }}>
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                            <Grid >
                                <Typography level="h1">Submission Details</Typography>
                            </Grid>
                            <Grid >
                                <Button variant="outlined" color="neutral" size="md" startDecorator={<IosShareIcon />} onClick={() => setOpen(true)}>
                                    Share This Submission
                                </Button>
                            </Grid>
                            <Grid xs={12} mt={2}>
                                <Typography level="body-lg" fontWeight={700}>Workplace</Typography>
                            </Grid>
                            <Grid >
                                <Card variant="outlined"
                                >
                                    <Link href={`/hospitals/${data.WORKPLACE_ID}/about`} underline="always">
                                        <Typography color="primary" level="h6" fontWeight={700}>{data.WORKPLACE_NAME}</Typography>
                                    </Link>
                                    <Typography level="body-lg">{`${data.WORKPLACE_CITY}, ${data.WORKPLACE_STATE}`}</Typography>
                                </Card>
                            </Grid>

                            <Grid xs={12}>
                                <Typography level="body-lg" fontWeight={700}>Background</Typography>
                            </Grid>
                            <Grid >
                                <Card variant="outlined">
                                    <Typography level="body-md">Specialty</Typography>
                                    <Typography level="body-lg" fontWeight={600}>{nurseType}</Typography>
                                </Card>
                            </Grid>
                            <Grid >
                                <Card variant="outlined">
                                    <Typography level="body-md">Level of Education</Typography>
                                    <Typography level="body-lg" fontWeight={600}>{String(data.LEVEL_OF_EDUCATION).toUpperCase()}</Typography>
                                </Card>
                            </Grid>
                            <Grid >
                                <Card variant="outlined">
                                    <Typography level="body-md">Years at Hospital</Typography>
                                    <Typography level="body-lg" fontWeight={600}>{!data.PROTECTED ? data.YEARS_AT_HOSPITAL : "*protected"}</Typography>
                                </Card>
                            </Grid>
                            <Grid >
                                <Card variant="outlined">
                                    <Typography level="body-md">Years of Experience</Typography>
                                    <Typography level="body-lg" fontWeight={600}>{data.YEARS_OF_EXPERIENCE}</Typography>
                                </Card>
                            </Grid>
                            <Grid >
                                <Card variant="outlined">
                                    <Typography level="body-md">Submission Date</Typography>
                                    <Typography level="body-lg" fontWeight={600}>{data.createdAt.toDate().toLocaleDateString()}</Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12}>
                                <Typography level="body-lg" fontWeight={700}>Ratings</Typography>
                            </Grid>
                            <Grid >
                                <DisplayRatings data={data} entity={ENTITIES.individual} />
                            </Grid>
                            <Grid xs={12}>
                                <Typography level="body-lg" fontWeight={700}>Comp Details</Typography>
                            </Grid>

                            <Grid xs={12} sm="auto" md="auto">
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Base
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                        ${data.BASE_RATE}/hr
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12} sm="auto" md="auto">
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Weekend Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                        {getDifferentialString(data.WEEKEND_DIFFERENTIAL_RATE, data.WEEKEND_DIFFERENTIAL_METRIC, data.PROTECTED)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12} sm="auto" md="auto">
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Night Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                        {getDifferentialString(data.NIGHT_DIFFERENTIAL_RATE, data.NIGHT_DIFFERENTIAL_METRIC, data.PROTECTED)}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid xs={12} sm="auto" md="auto">
                                <Card variant="outlined">
                                    <Typography level="body-md">
                                        Charge Nurse Differential
                                    </Typography>
                                    <Typography level="body-lg" fontWeight={600}>
                                        {getDifferentialString(data.CHARGE_NURSE_DIFFERENTIAL_RATE, data.CHARGE_NURSE_DIFFERENTIAL_METRIC, data.PROTECTED)}
                                    </Typography>
                                </Card>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Section>
        );
    }
}