import { apiRequestExternal } from "./util.js";

const missingInfoEndpoint = 'https://formspree.io/f/xlevlpvr';
const incorrectInfoEndpoint = 'https://formspree.io/f/moqblkbe';

function submitMissingInfo(data) {
  return apiRequestExternal(missingInfoEndpoint, "POST", data);
}

function submitIncorrectInfo(data) {
  return apiRequestExternal(incorrectInfoEndpoint, "POST", data)
}

const contact = { submitMissingInfo, submitIncorrectInfo };

export default contact;
