import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

const firebaseSendVerificationCode = httpsCallable(functions, "sendVerificationCode");
const firebaseValidateVerificationCode = httpsCallable(functions, "validateVerificationCode");
const fireabaseCheckIfEmailAlreadyExistsInSubmission = httpsCallable(functions, "checkIfEmailAlreadyExistsInSubmission");
const firebaseVerifySubmission = httpsCallable(functions, "verifySubmission");

export function sendVerificationCode(request) {
    return firebaseSendVerificationCode(request);
}

export async function validateVerificationCode(request) {
    return firebaseValidateVerificationCode(request);
}

export async function checkIfEmailAlreadyExistsInSubmission(request) {
    return fireabaseCheckIfEmailAlreadyExistsInSubmission(request);
}

export async function verifySubmission(request) {
    return firebaseVerifySubmission(request);
}