import React, { useEffect, useState } from "react";
import Section from "../general/Section";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import Table from '@mui/joy/Table';
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import { db } from "../../util/firebase";
import { collection, query, orderBy, startAfter, limit, endBefore, limitToLast, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../util/auth";
import { getUser } from "../../util/db";
import ExamineModal from "./ExamineModal";

export default function DashboardSection(props) {

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [validViewer, setValidViewer] = useState(false);
    const [submissionId, setSubmissionId] = useState("");
    const auth = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const first = query(collection(db, "newSubmissions"), orderBy("createdAt", "desc"), limit(10));
        const unsubscribe = onSnapshot(first, (querySnapshot) => {
            const submissions = [];
            querySnapshot.forEach((doc) => {
                submissions.push({key: doc.id, ...doc.data()});
            })
            setList(submissions);
        });
        return () => unsubscribe();
        }
        fetchData();
    }, [])

    useEffect(() => {
        const checkUser = async () => {
            if (auth.user) {
                try {
                    const user = await getUser(auth.user.uid);
                    if (user.ADMIN) {
                        setValidViewer(true);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        checkUser();
    }, [auth.user])

    const showNext = ({ item }) => {
        if (list.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchNextData = async () => {
                const next = query(collection(db, "newSubmissions"), orderBy("createdAt"), limit(10), startAfter(item.createdAt));
                const unsubscribe = onSnapshot(next, (querySnapshot) => {
                    const submissions = [];
                    querySnapshot.forEach((doc) => {
                        submissions.push({key: doc.id, ...doc.data()});
                    })
                    setList(submissions);
                    setPage(page + 1);
                });
                return () => unsubscribe();
            }
            fetchNextData();
        }
    };

    const showPrevious = ({ item }) => {
        if (list.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchPreviousData = async () => {
                const previous = query(collection(db, "submissions"), orderBy("createdAt"), endBefore(item.createdAt), limitToLast(10));
                const unsubscribe = onSnapshot(previous, (querySnapshot) => {
                    const submissions = [];
                    querySnapshot.forEach((doc) => {
                        submissions.push({key: doc.id, ...doc.data()});
                    })
                    setList(submissions);
                    setPage(page - 1);
                });
                return () => unsubscribe();
            }
            fetchPreviousData();
        }
    };

    if (validViewer) {
        return (
            <Section bgColor={props.bgColor}
                size={props.size}>
                <Container>
                    <ExamineModal submissionId={submissionId} setSubmissionId={setSubmissionId}/>
                    <Typography mb={1} level="title-lg">Verify Submissions</Typography>
                        <Sheet variant="outlined" sx={{overflow: 'auto'}}>
                        <Table sx={{minWidth: 600}}>
                            <thead>
                                <tr>
                                    <th>Created At</th>
                                    <th align="right">Submission_ID</th>
                                    <th align="right">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((row) => {
                                    return (
                                        <tr
                                            key={row.key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <td>
                                                {String(row.createdAt.toDate())}
                                            </td>
                                            <td>{row.key}</td>
                                            <td>
                                                <Button variant="outlined" onClick={() => setSubmissionId(row.key)}>View</Button>
                                            </td> 
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </Sheet>
                    {
                        //show previous button only when we have items
                        page === 1 ? '' :
                            <Button onClick={() => showPrevious({ item: list[0] })} sx={{ mt: 2 }}>Previous</Button>
                    }

                    {
                        //show next button only when we have items
                        list.length < 10 ? '' :
                            <Button onClick={() => showNext({ item: list[list.length - 1] })} sx={{ mt: 2 }}>Next</Button>
                    }
                </Container>
            </Section>
        )
    } else {
        return (
            <Section>
                <Container>
                    <Typography>Not an authorized user</Typography>
                </Container>
            </Section>
        )
    }
}