import React, { useState } from "react";
import Typography from "@mui/joy/Typography";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Modal, ModalClose, ModalDialog } from "@mui/joy";
import { Check } from "@mui/icons-material";

export default function ShareUrlModal({open, setOpen, shareUrl}) {
    const [linkCopied, setLinkCopied] = useState(false);
    return (
        <Modal open={open} onClose={() => {
            setLinkCopied(false);
            setOpen(false);
            }}>
            <ModalDialog size="lg">
            <ModalClose/>
                <Typography level="title-lg">Share with Another Nurse</Typography>
                <Typography>Copy the shareable link and send it to a fellow nurse.</Typography>
                {linkCopied && <Alert color="success" endDecorator={<Check/>}>Link copied</Alert>}
                <Button size="md" startDecorator={<ContentCopyIcon/>} onClick={() => {
                        navigator.clipboard.writeText(shareUrl);
                        setLinkCopied(true);
                    }}>
                         Copy Link
                    </Button>
                    <Typography level="body-sm" mt={2}>Or, manually copy this link:</Typography>

                <Typography variant="outlined">{shareUrl}</Typography>
                </ModalDialog>
        </Modal>
    )
}