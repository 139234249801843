/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from '@mui/joy/Table';
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Row from "../table/Row";
import CallToAddSubmission from '../general/CallToAddSubmission';
import Stack from "@mui/joy/Stack";
import AddIcon from "@mui/icons-material/Add";
import CardOverflow from "@mui/joy/CardOverflow";
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import { Divider } from '@mui/joy';
import { useViewport } from '../../util/util';

export default function SpecialtySubmissionsTable({submissions}) {
    const {width} = useViewport();
    const step = 2;
    const breakpoint = 670;
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(10);
    const [displayedSubmissions, setDisplayedSubmissions] = useState(getStarterSubmissions());

    function getStarterSubmissions() {
        let i = startIndex;
        let z = endIndex;
        if (submissions.length < step || submissions.length < endIndex) {
            z = submissions.length;
        }
        let arr = [];
        for (i; i <= z - 1; i++) {
            arr.push(submissions[i]);
        }
        return arr;
    }

    const next = () => {
        setStartIndex(currentIndex => currentIndex + step);
        setEndIndex(currentIndex => currentIndex + step);
        let i = startIndex;
        let arr = [];
        for (i; i <= endIndex; i++) {
            arr.push(submissions[i]);
        }
        setDisplayedSubmissions(arr);
    }

    const prev = () => {
        setStartIndex(currentIndex => currentIndex - step);
        setEndIndex(currentIndex => currentIndex - step);
        let i = startIndex;
        let arr = [];
        for (i; i <= endIndex; i++) {
            arr.push(submissions[i]);
        }
        setDisplayedSubmissions(arr);
    }

    return (
            <Card variant="outlined" sx={{p: 0, m: 0}}>
                <CardContent>
                <Table
                size={width > breakpoint ? "lg" : "md"}
                sx={{
                    '& > tbody > tr:nth-of-type(odd) > td, & > tbody > tr:nth-of-type(odd) > th[scope="row"]':
                      {
                        borderBottom: 0,
                      },
                  }}
              >
                    <thead>
                        <tr>
                            <th colSpan={1}/>
                            <th colSpan={2}>
                                    Hospital                            
                            </th>
                            <th colSpan={2}>
                                Date
                            </th>
                            <th colSpan={2}>
                                    Base Rate
                            </th>
                            <th colSpan={2}>
                                    Specialty
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedSubmissions.map((row, index) => {
                                return (
                                    <Row row={row} key={index} />
                                );
                            })
                        }
                    </tbody>
                </Table>
                {submissions.length === 0 && <CallToAddSubmission />}
                </CardContent>
                <CardOverflow sx={{ bgcolor: 'background.level1', p: 1 }}>
                <CardActions>
                    <Stack direction="row" divider={<Divider orientation="vertical"/>} justifyContent="center" spacing={2}>
                        <Button color="neutral" variant="outlined" size="sm" startDecorator={<AddIcon />} component="a" href="../../contribute">Contribute</Button>
                        <Button size="sm" sx={{mr: 1}} variant="solid" startDecorator={<KeyboardArrowLeft />} 
                        disabled={startIndex - step < 0}
                        onClick={() => prev()}>Prev</Button>
                        <Button size="sm" variant="solid" endDecorator={<KeyboardArrowRight />}
                         disabled={endIndex + step > submissions.length} onClick={() => next()}>Next</Button>
                    </Stack>
                </CardActions>
                </CardOverflow>
                </Card>


    );
}
