import React from "react";
import Tabs from "@mui/joy/Tabs"
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import Link from "@mui/joy/Link"

export default function SpecialtyTabBar(props) {
    return (
        <Tabs value={props.routePathname} variant="outlined">
            <TabList size="lg">
                <Link href={`/hospitals/${props.hospitalId}/about`} underline="none">
                <Tab value={`/hospitals/${props.hospitalId}/about`}>
                    Overview
                </Tab>
                </Link>
                <Link href={`/hospitals/${props.hospitalId}/specialties/${props.specialtyId}`} underline="none">
                <Tab value={`/hospitals/${props.hospitalId}/specialties/${props.specialtyId}`}>
                    Submissions
                </Tab>
                </Link>
            </TabList>
        </Tabs>
    );
}
