import React, {useState} from "react";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import IosShareIcon from '@mui/icons-material/IosShare';
import Box from "@mui/material/Box";
import ShareUrlModal from "./ShareUrlModal";

export default function CallToAddSubmission() {
    const shareUrl = "https://realnurse.info/contribute"
    const [open, setOpen] = useState(false);

    return (
        <Box p={2} textAlign="center" justifyContent="center" alignItems="center" display="flex">
            <ShareUrlModal open={open} setOpen={setOpen} shareUrl={shareUrl}/>
            <Grid container spacing={2} justifyContent="center" alignItems="center" maxWidth={700}>
                <Grid  xs={12}>
                    <Typography level="h4">
                        This hospital needs more submissions
                    </Typography>
                </Grid>
                <Grid  xs={12}>
                    <Typography level="body-md" mb={2}>
                    Realnurse is not very useful without data, so we ask that you please contribute your information and empower fellow nurses to advocate for fair pay and work conditions.                    </Typography>
                </Grid>
                <Grid >
                    <Button color="primary" variant="solid" endDecorator={<AddIcon />} component="a" href="/contribute">
                        Contribute Your Info
                    </Button>
                </Grid>
                <Grid >
                    <Button color="primary" variant="soft" endDecorator={<IosShareIcon />} onClick={() => setOpen(true)}>
                        Invite a Nurse
                    </Button>
                </Grid>
                <Grid  xs={12}>
                    <Typography level="body-md">(Only Takes 60 seconds)</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}