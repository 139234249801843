import React from "react";
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Box from '@mui/joy/Box';
import { Star, StarBorder } from "@mui/icons-material";
import Tooltip from '@mui/joy/Tooltip';
import Typography from "@mui/joy/Typography";
import { rating_specialties } from "../../constants";



export default function StarRating({ name, value, handleChange }) {

    return (
        <Box>
            <RadioGroup
                aria-labelledby="product-size-attribute"
                sx={{ display: 'flex', flexDirection: 'row' }}
                variant="outlined"
            >
                <Tooltip title={rating_specialties[1]} variant="solid">
                    <Box
                        key={1}
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 40,
                            '&:not([data-first-child])': {
                                borderLeft: '1px solid',
                                borderColor: 'divider',
                            },
                            [`&[data-first-child] .${radioClasses.action}`]: {
                                borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                            [`&[data-last-child] .${radioClasses.action}`]: {
                                borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                        })}
                    >
                        <Radio
                            name={name}
                            value={1}
                            onChange={handleChange}
                            disableIcon
                            overlay
                            label={value >= 1 ? <Star /> : <StarBorder />}
                            variant={value >= 1 ? "solid" : "plain"}
                            color="primary"
                            slotProps={{
                                input: { 'aria-label': 1 },
                                action: {
                                    sx: { borderRadius: 0, transition: 'none' },
                                },
                                label: { sx: { lineHeight: 0 } },
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={rating_specialties[2]} variant="solid" arrow>
                    <Box
                        key={2}
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 40,
                            '&:not([data-first-child])': {
                                borderLeft: '1px solid',
                                borderColor: 'divider',
                            },
                            [`&[data-first-child] .${radioClasses.action}`]: {
                                borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                            [`&[data-last-child] .${radioClasses.action}`]: {
                                borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                        })}
                    >
                        <Radio
                            name={name}
                            onChange={handleChange}
                            value={2}
                            disableIcon
                            overlay
                            label={value >= 2 ? <Star /> : <StarBorder />}
                            variant={value >= 2 ? "solid" : "plain"}
                            color="primary"
                            slotProps={{
                                input: { 'aria-label': 1 },
                                action: {
                                    sx: { borderRadius: 0, transition: 'none' },
                                },
                                label: { sx: { lineHeight: 0 } },
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={rating_specialties[3]} variant="solid" arrow>
                    <Box
                        key={3}
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 40,
                            '&:not([data-first-child])': {
                                borderLeft: '1px solid',
                                borderColor: 'divider',
                            },
                            [`&[data-first-child] .${radioClasses.action}`]: {
                                borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                            [`&[data-last-child] .${radioClasses.action}`]: {
                                borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                        })}
                    >
                        <Radio
                            name={name}
                            onChange={handleChange}
                            value={3}
                            disableIcon
                            overlay
                            label={value >= 3 ? <Star /> : <StarBorder />}
                            variant={value >= 3 ? "solid" : "plain"}
                            color="primary"
                            slotProps={{
                                input: { 'aria-label': 1 },
                                action: {
                                    sx: { borderRadius: 0, transition: 'none' },
                                },
                                label: { sx: { lineHeight: 0 } },
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={rating_specialties[4]} variant="solid" arrow>
                    <Box
                        key={4}
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 40,
                            '&:not([data-first-child])': {
                                borderLeft: '1px solid',
                                borderColor: 'divider',
                            },
                            [`&[data-first-child] .${radioClasses.action}`]: {
                                borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                            [`&[data-last-child] .${radioClasses.action}`]: {
                                borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                        })}
                    >
                        <Radio
                            name={name}
                            onChange={handleChange}
                            value={4}
                            disableIcon
                            overlay
                            label={value >= 4 ? <Star /> : <StarBorder />}
                            variant={value >= 4 ? "solid" : "plain"}
                            color="primary"
                            slotProps={{
                                input: { 'aria-label': 1 },
                                action: {
                                    sx: { borderRadius: 0, transition: 'none' },
                                },
                                label: { sx: { lineHeight: 0 } },
                            }}
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={rating_specialties[5]} variant="solid" arrow>
                    <Box
                        key={5}
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 40,
                            '&:not([data-first-child])': {
                                borderLeft: '1px solid',
                                borderColor: 'divider',
                            },
                            [`&[data-first-child] .${radioClasses.action}`]: {
                                borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                            [`&[data-last-child] .${radioClasses.action}`]: {
                                borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                                borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
                            },
                        })}
                    >
                        <Radio
                            name={name}
                            value={5}
                            onChange={handleChange}
                            disableIcon
                            overlay
                            label={value >= 5 ? <Star /> : <StarBorder />}
                            variant={value >= 5 ? "solid" : "plain"}
                            color="primary"
                            slotProps={{
                                input: { 'aria-label': 1 },
                                action: {
                                    sx: { borderRadius: 0, transition: 'none' },
                                },
                                label: { sx: { lineHeight: 0 } },
                            }}
                        />
                    </Box>
                </Tooltip>
            </RadioGroup>
            <Typography level="body-sm">{value !== null ?rating_specialties[value] : "No rating yet"}</Typography>
        </Box>
    )
}