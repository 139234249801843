import Modal from '@mui/joy/Modal';
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import LandingSearch from './LandingSearch';
import { useViewport } from "../../util/util";

export default function LandingSearchModal({open, setOpen}) {
    const { width } = useViewport();
    const breakpoint = 620;

    if (width > breakpoint) {
  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalDialog
        sx={{width: 600, height: 400}}
        layout="center"
      >
        <ModalClose variant="outlined" />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          Search Hospitals
        </Typography>
        <LandingSearch setOpen={setOpen}/>
      </ModalDialog>
    </Modal>
  );
    } else {
        return (
            <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={(_event, reason) => {
        setOpen(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalDialog
        layout="fullscreen"
      >
        <ModalClose variant="outlined" />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          Search Hospitals
        </Typography>
        <LandingSearch setOpen={setOpen}/>
      </ModalDialog>
    </Modal>
        );
    }

}