import React, { useState } from "react";
import Grid from "@mui/joy/Grid";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import StarRating from "./StarRating";
import IconButton from "@mui/joy/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import { stress_options } from "../../constants";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

export default function RatingsSection({ formik }) {
    const [dialogContent, setDialogContent] = useState({
        specialty: "",
        content: ""
    });

    const handleSelectStressChange = (event, newValue) => {
        formik.setFieldValue("STRESS_LEVEL", newValue);
    };

    return (
        <Card variant="outlined" sx={{ maxWidth: 650 }}>
            <Modal open={dialogContent.specialty.length > 0 && dialogContent.content.length > 0}
                onClose={() => setDialogContent({ specialty: "", content: "" })}>
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>{dialogContent.specialty}</DialogTitle>
                    <DialogContent>{dialogContent.content}</DialogContent>
                </ModalDialog>
            </Modal>
            <Grid container spacing={2}>
                <Grid  xs={12}>
                    <Typography level="h4">Ratings</Typography>
                </Grid>
                <Grid  xs={12} md={6}>
                    <FormControl error={Boolean(formik.errors.RN_TO_PATIENT_RATIO_RATING) && formik.touched.RN_TO_PATIENT_RATIO_RATING} required>
                        <FormLabel>
                            RN to patient ratio
                            <IconButton size="sm" onClick={() => setDialogContent({
                                specialty: "RN to Patient Ratio Rating",
                                content: "How good is your unit at assigning you a reasonable number of patients to manage?"
                            })}>
                                <InfoOutlined />
                            </IconButton>
                        </FormLabel>
                        <StarRating
                            name="RN_TO_PATIENT_RATIO_RATING"
                            value={formik.values.RN_TO_PATIENT_RATIO_RATING}
                            handleChange={formik.handleChange}
                        />
                        {(formik.errors.RN_TO_PATIENT_RATIO_RATING && formik.touched.RN_TO_PATIENT_RATIO_RATING) &&
                            <FormHelperText>{formik.errors.RN_TO_PATIENT_RATIO_RATING}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12} md={6}>
                    <FormControl error={Boolean(formik.errors.NURSING_SUPPORT_RATING) && formik.touched.NURSING_SUPPORT_RATING} required>
                        <FormLabel>
                            Nursing Support
                            <IconButton size="sm" onClick={() => setDialogContent({
                                specialty: "Nursing Support",
                                content: "How good are the CNAs, transports, and other staff that support the nurses?"
                            })}>
                                <InfoOutlined />
                            </IconButton>
                        </FormLabel>
                        <StarRating
                            name="NURSING_SUPPORT_RATING"
                            value={formik.values.NURSING_SUPPORT_RATING}
                            handleChange={formik.handleChange}
                        />
                        {(formik.errors.NURSING_SUPPORT_RATING && formik.touched.NURSING_SUPPORT_RATING) &&
                            <FormHelperText>{formik.errors.NURSING_SUPPORT_RATING}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12} md={6}>
                    <FormControl error={Boolean(formik.errors.HOSPITAL_MANAGEMENT_RATING) && formik.touched.HOSPITAL_MANAGEMENT_RATING} required>
                        <FormLabel>
                            Hospital management
                            <IconButton size="sm" onClick={() => setDialogContent({
                                specialty: "Hospital Management",
                                content: "How well do the managers, executives, and other business personel run the hospital?"
                            })}>
                                <InfoOutlined />
                            </IconButton>
                        </FormLabel>
                        <StarRating
                            name="HOSPITAL_MANAGEMENT_RATING"
                            value={formik.values.HOSPITAL_MANAGEMENT_RATING}
                            handleChange={formik.handleChange}
                        />
                        {(formik.errors.HOSPITAL_MANAGEMENT_RATING && formik.touched.HOSPITAL_MANAGEMENT_RATING) &&
                            <FormHelperText>{formik.errors.HOSPITAL_MANAGEMENT_RATING}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12} md={6}>
                    <FormControl error={Boolean(formik.errors.ONBOARDING_RATING) && formik.touched.ONBOARDING_RATING} required>
                        <FormLabel>
                            Onboarding Experience
                            <IconButton size="sm" onClick={() => setDialogContent({
                                specialty: "Onboarding Experience",
                                content: "How good was your onboarding experience when you first got hired? Was there support and guidance or were you thrown right in?"
                            })}>
                                <InfoOutlined />
                            </IconButton>
                        </FormLabel>
                        <StarRating
                            name="ONBOARDING_RATING"
                            value={formik.values.ONBOARDING_RATING}
                            handleChange={formik.handleChange}
                        />
                        {(formik.errors.ONBOARDING_RATING && formik.touched.ONBOARDING_RATING) &&
                            <FormHelperText>{formik.errors.ONBOARDING_RATING}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid  xs={12}>
                    <FormControl error={Boolean(formik.errors.STRESS_LEVEL) && formik.touched.STRESS_LEVEL} required>
                        <FormLabel>Select Stress Level</FormLabel>
                        <Select onChange={handleSelectStressChange} id="select-stress" name="STRESS_LEVEL" value={formik.values.STRESS_LEVEL} placeholder="Please select one">
                            {stress_options.map((specialty) => {
                                return (
                                    <Option key={specialty.value} value={specialty.value}>{specialty.label}</Option>
                                );
                            })}
                        </Select>
                        {(formik.errors.STRESS_LEVEL && formik.touched.STRESS_LEVEL) && <FormHelperText>{formik.errors.STRESS_LEVEL}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </Card>
    )
}